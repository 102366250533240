import { Checkbox, ChoiceGroup, ComboBox, CompoundButton, DefaultButton, Dialog, DialogFooter, DialogType, IChoiceGroupOption, IModalProps, Label, mergeStyles, MessageBar, MessageBarType, PrimaryButton, Separator, Stack, Text } from "@fluentui/react";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import MicrosoftLogin from "react-microsoft-login";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader";
import "./CustomerTenantSetupBox.css";
import { useForceUpdate } from "@fluentui/react-hooks";
import { t } from "i18next";

const tenantSetupBoxClassName = mergeStyles([{
}]);

const textClassName = mergeStyles([{
    fontSize: 14
}]);

const buttonContainerClassName = mergeStyles([{
}]);

const checkBoxClassName = mergeStyles([{
    paddingTop: 15
}])

const separatorStyles = {
    root: [{
      selectors: { // add selectors here
        '::before': {
          background: '#bbb',
        },
      }
    }]
};

export interface ICustomerTenantSetupBoxProps {
    onDismiss: () => void;
    onCancel: () => void;
    showModal: boolean;
    saveInProgress: boolean;
}


const pageCardContentBodyClassName = mergeStyles([{
    width: '100%',
    overflowY: "auto",
    overflowX: "hidden"
}]);

const pageCardModalContentClassName = mergeStyles([{
    width: '100%',
    marginTop: 20
}]);

const pageCardFormContentClassName = mergeStyles([{
    width: '100%'
}]);

export const CustomerTenantSetupBox : React.FC<ICustomerTenantSetupBoxProps> = (props) => {
    const customerService = new CustomerService();
    const customerInfoContext = useContext(CustomerInformationContext);

    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const [answer, setAnswer] = useState(1);
    const [gatheringData, setGatheringData] = useState(false);

    const pageCardModalProps: Partial<IModalProps> = {
        isBlocking: false,
        dragOptions: undefined,
        styles: {
            main: {
                minWidth: '500px !important',
                position: 'absolute',
                top: 50
            }
        }
    };

    const graphScopes: string[] = [
        "https://graph.microsoft.com/user.read"
    ];

    const hasDataAcquired = () : boolean => {
        return ((! gatheringData) && (customerInfoContext.setupConfig.tenantId != "" && customerInfoContext.setupConfig.tenantId != undefined));
    }

    useEffect(() => {
        if (gatheringData)
        {
            setGatheringData(false);
            if ((answer == 3) && (customerInfoContext.setupConfig.hasTenant))
                setAnswer(1);
        }
    }, [customerInfoContext.setupConfig]);

    const loginHandler = (err: any, data: any, msal: any) => {
        if (! err && data)
        {
            customerService.gatherCustomerTenantDetail(data.accessToken).then((resp) => {
                customerInfoContext.reloadSetupConfig();
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerTenantSetupBox:Err00001Text'));
                setGatheringData(false);
            });
        }
        else
            setGatheringData(false);
    };

    const handleOnChange = (ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined): void  => {
        setAnswer(Number(option!.key));
    }

    let loginContent = <></>;
    if (answer == 3)
    {
        if ((! gatheringData) && (customerInfoContext.setupConfig.tenantId == "" || customerInfoContext.setupConfig.tenantId == undefined))
            loginContent = <>
                            <Stack.Item className={textClassName}>
                                {t('customerCenter:CustomerTenantSetupBox:answer3Text')}
                            </Stack.Item>
                            <Stack.Item className={buttonContainerClassName}>
                                <MicrosoftLogin
                                    withUserData={true}
                                    clientId={"fc190b0b-2f86-46d9-b131-a64b567284aa"}
                                    authCallback={(err, data, msal) => {
                                        if (! gatheringData) {
                                            setGatheringData(true);
                                            loginHandler(err, data, msal);
                                        }
                                    }}
                                    graphScopes={[]}
                                    className="ms-signin-button"
                                    buttonTheme={"dark"}
                                    useLocalStorageCache={false}
                                    prompt="select_account"
                                />
                            </Stack.Item>
                            </>;
    else  
        loginContent = <>
            </>;
    }
    else if (answer == 1)
    {
        if ((! gatheringData) && (customerInfoContext.setupConfig.tenantId == "" || customerInfoContext.setupConfig.tenantId == undefined))
            loginContent = <>
                            <Stack.Item className={textClassName}>
                                {t('customerCenter:CustomerTenantSetupBox:answer1Text')}
                            </Stack.Item>
                            <Stack.Item className={buttonContainerClassName}>
                                <MicrosoftLogin
                                    withUserData={true}
                                    clientId={"fc190b0b-2f86-46d9-b131-a64b567284aa"}
                                    authCallback={(err, data, msal) => {
                                        if (! gatheringData) {
                                            setGatheringData(true);
                                            loginHandler(err, data, msal);
                                        }
                                    }}
                                    graphScopes={[]}
                                    className="ms-signin-button"
                                    buttonTheme={"dark"}
                                    useLocalStorageCache={false}
                                    prompt="select_account"
                                />
                            </Stack.Item>
                            </>;
        else  
            loginContent = <>
                </>;
    }



    let messageBar = <></>;
    if (props.saveInProgress)
        messageBar = <MessageBar>{t('customerCenter:CustomerTenantSetupBox:messageBar:messageContent1')}</MessageBar>;
    else if (gatheringData)
        messageBar = <MessageBar>{t('customerCenter:CustomerTenantSetupBox:messageBar:messageContent2')}</MessageBar>;
    else if (hasDataAcquired())
        messageBar = <MessageBar messageBarType={MessageBarType.success}>{t('customerCenter:CustomerTenantSetupBox:messageBar:messageContent3')}</MessageBar>;

    const options: IChoiceGroupOption[] = [
        { key: '1', text: t('customerCenter:CustomerTenantSetupBox:options:opt1') },
        { key: '2', text: t('customerCenter:CustomerTenantSetupBox:options:opt2') },
        { key: '3', text: t('customerCenter:CustomerTenantSetupBox:options:opt3') },
    ];

    return (
        <Dialog hidden={! props.showModal}
                    modalProps={pageCardModalProps}
                    onDismiss={props.onCancel}
                    dialogContentProps={{
                        title: t('customerCenter:CustomerTenantSetupBox:dialogTitle')!,
                        type: DialogType.largeHeader
                    }}>
                <Stack key="modalContent" className={pageCardModalContentClassName} tokens={{
                    childrenGap: 15
                }}>
                    <Stack.Item key="modalBodyContent" className={pageCardContentBodyClassName}>
                        <Stack key="formContainer" className={pageCardFormContentClassName}>
                            <Stack key="tenantSetupBox" className={tenantSetupBoxClassName}>
                                <Stack.Item>
                                    <ChoiceGroup defaultSelectedKey={answer.toString()} options={options} onChange={handleOnChange} label={t('customerCenter:CustomerTenantSetupBox:choiceLabel')!} required={true} disabled={props.saveInProgress || hasDataAcquired()} />
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                    {loginContent}
                    {messageBar}
                    <Stack.Item key="modalFooterContent">
                        <DialogFooter>
                            <PrimaryButton text={t('leadDashboard:buttonText')!} onClick={() => {
                                if ((answer == 0) || (answer == 3))
                                    ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerTenantSetupBox:Err00002Text'))
                                else if (answer == 1)
                                {
                                    if ((customerInfoContext.setupConfig.tenantId == "") || (customerInfoContext.setupConfig.tenantId == undefined))
                                        ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerTenantSetupBox:Err00003Text'));
                                    else
                                        props.onDismiss();
                                }    
                                else
                                    props.onDismiss();
                            }} 
                            disabled={props.saveInProgress || gatheringData} />
                        </DialogFooter>
                    </Stack.Item>
                </Stack>
        </Dialog>
    );
}