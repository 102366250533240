import { useEffect, useRef, useState } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { PartnerService } from "../../../../ApplicationCode/Partner/PartnerService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { BCNProcessTest } from "../../../../Model/AdminModel";
import { CrmSetup } from "../../../../Model/PartnerModels";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { CommonUploadFileDialog } from "../../../Common/CommonUploadFileDialog/CommonUploadFileDialog";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const AdminBCNProcessTestList : React.FC = () => {
    
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [loaderMessage, setLoaderMessage] = useState("Stiamo caricando la lista richiesta ...");
    const [processTest, setProcessTest] = useState<BCNProcessTest>(new BCNProcessTest());
    const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
    const [crmSetup, setCrmSetup] = useState(new CrmSetup());
    const ref = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    const partnerService = new PartnerService();

    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.ToLoad) {
            partnerService.getCrmLeadData().then((resp)=>{
                setCrmSetup(resp);
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch((jqXHR)=>{
                ToastService.showMessage(MessageType.Error, "Attenzione: non è stato possibile recuperare i dati del CRM, le azioni basate su ciò potrebbero non funzionare");
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
        }
    },[dataStatus]);

    // const syncBCNProcessTables = () => {
    //     adminService.syncBCNProcessTables().then((resp:string)=>{
    //         setDataStatus(SystemDataLoadingStatus.Loaded)
    //         ToastService.showMessage(MessageType.Success, "Sincronizzazione tabelle process module completata con successo");
    //     })
    //     .catch((jqXHR)=>{
    //         setDataStatus(SystemDataLoadingStatus.Loaded)
    //         ToastService.showMessage(MessageType.Error, jqXHR.message);
    //     })
    // }

    const uploadBCNProcessTestVideo = (file:File) => {
        setLoaderMessage("Caricamento video in corso ...")
        setSaveInProgress(true);
        setDataStatus(SystemDataLoadingStatus.Loading);
        
        adminService.uploadVideoDocs(file).then((resp)=>{
            setShowUploadDialog(false);
            setDataStatus(SystemDataLoadingStatus.Loaded);
            setSaveInProgress(false);
            setLoaderMessage("");
            ToastService.showMessage(MessageType.Success, "Caricamento completato con successo");
         })
         .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            setLoaderMessage("");
            setSaveInProgress(false);
            ToastService.showMessage(MessageType.Error, "Errore nel caricamento: "+jqXHR);
         })
    }

    const elaborateVideo = () => {
        const result: string = adminService.elaborateVideo();
        setDataStatus(SystemDataLoadingStatus.Loaded);
        ToastService.showMessage(MessageType.Information, result);
    }
    
    if (dataStatus !== SystemDataLoadingStatus.Loaded)
        return <Loader text={loaderMessage}></Loader>;
    else
    return (
        <>
            <PageContainer endpoint="/odata/bcn/process/test" pageType={SystemPageType.List} title="BCN Process Test" backAllowed cardRef={ref}>   
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Process}>
                        {/* <ActionEntry name="syncProcessModuleTables" label="Sincronizza tabelle process module" isPromoted iconName="SyncOccurence" onClick={(req:any) => {
                            setDataStatus(SystemDataLoadingStatus.Loading);
                            setLoaderMessage("Sincronizzazione tabelle process module in corso, Attendere...");
                            syncBCNProcessTables();
                        }}></ActionEntry> */}
                        <ActionEntry name="uploadVideo" label="Carica video" iconName="Upload" isPromoted onClick={() => {
                            setShowUploadDialog(true);
                        }}></ActionEntry>
                        <ActionEntry name="elaborateVideo" label="Elabora video" iconName="Sync" isPromoted onClick={() => {
                            setDataStatus(SystemDataLoadingStatus.Loading);
                            setLoaderMessage("Elaborazione video in corso, Attendere...");
                            elaborateVideo();
                        }}></ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="crmKnowledgeArticle" label="CRM knowledge article" iconName="CRMReport" isPromoted runOnRec onClick={(req:any) => {
                                window.open(crmSetup.knowledgeArticleBaseAddress+req.crmReferenceId);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="docsArticle" label="Docs knowledge article" iconName="DocumentSearch" isPromoted runOnRec onClick={(req:any) => {
                                window.open("/docs/"+req.crmReferenceId);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Process Module" fieldName="processModule" minWidth={120} maxWidth={250} />
                    <ListHeaderEntry name="Process Code" fieldName="processCode" minWidth={120} maxWidth={250} />
                    <ListHeaderEntry name="Line No." fieldName="lineNo" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={400} maxWidth={250} />
                    <ListHeaderEntry name="Tipo Process Test" fieldName="processTestType" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Stato progetto" fieldName="projectStatus" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione gruppo training" fieldName="trainingGroupDescription" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name="Durata stimata" fieldName="estimatedDuration" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Tipo oggetto" fieldName="objectType" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Id oggetto" fieldName="objectId" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Pacchetto data migration" fieldName="dataMigrationPackage" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name="Link video" fieldName="videoLink" minWidth={450} maxWidth={450} />
                    <ListHeaderEntry name="Link docs 1" fieldName="docs1Link" minWidth={450} maxWidth={450} />
                    <ListHeaderEntry name="Link docs 2" fieldName="docs2Link" minWidth={450} maxWidth={450} />
                    <ListHeaderEntry name="Process FAQ" fieldName="processFAQ" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Test completato" fieldName="testCompleted" minWidth={110} maxWidth={250} />
                    <ListHeaderEntry name="User Id test completato" fieldName="userIdTestCompleted" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data test completato" fieldName="dateTimeTestCompleted" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="User Id test cominciato" fieldName="userIdTestStarted" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data test cominciato" fieldName="dateTimeTestStarted" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Abilitato" fieldName="enabled" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Process abilitato" fieldName="processEnabled" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="CRM Reference Id" fieldName="crmReferenceId" minWidth={250} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <CommonUploadFileDialog
                title="Carica video"
                subText="Seleziona il video da caricare"
                hidden={showUploadDialog}
                txtLabel="File"
                ext=".webm,.mp4"
                saveInProgress={saveInProgress}
                onSuccess={uploadBCNProcessTestVideo}
                onDismiss={()=>{setShowUploadDialog(false)}}
            />
        </>
    );
}