import React, {forwardRef} from "react";
import {SystemFieldType,SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminEmailTemplateStructureCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/email/template/structure" title={"Email Template Structure"} ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="code" label="Codice" type={SystemFieldType.String} />
                <CardField name="description" label="Descrizione" type={SystemFieldType.String} />
                <CardField name="fileName" label="Nome File" type={SystemFieldType.String} readonly />
                <CardField name="companyInformation" label="Company information" type={SystemFieldType.String} />
                <CardField name="privacyPolicyUrl" label="Privacy policy url" type={SystemFieldType.String} />
            </CardGroup>
        </PageContainer>
    );
});
