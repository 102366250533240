import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { ApplicationOutletContext, useOutlet } from "../../Common/ApplicationContainer/ApplicationContainer";

export const AdminApplicationContainer : React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let sessionStateContext: ISessionStateContext = useContext(SessionStateContext);
    const outletContext:ApplicationOutletContext = useOutlet();
    let adminService = new AdminService();

    useEffect(() => {
        outletContext.trySetDocsUrl(buildDocumentationPortalUrl())
    }, []);
    
    //TODO: TO DISMISS
    const buildDocumentationPortalUrl = (): string => {
        if(window.location.origin.indexOf('.it') >= 0){
            return('/app/customer/docs/it-IT');
        }
        else if (window.location.origin.indexOf('.us') >= 0){
            return('/app/customer/docs/en-US');
        }
        else if (window.location.origin.indexOf('localhost') >= 0){
            return('/app/customer/docs/' + process.env.REACT_APP_SYSTEM_BACKEND_DEFAULT_LOCALIZATION);
        }
        else{
            return('/docs');
        };
    }

    return (
        <Outlet></Outlet>
    );
    
}
