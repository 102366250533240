import React, { useContext, useEffect, useRef, useState } from "react";
import { Customer, CustomerDashboardCue, CustomerEnvironment } from "../../../../Model/CustomerModels";
import { SystemActionCategory, SystemDataLoadingStatus,OperationType, SystemEntity, SystemPage, SystemPageType } from "../../../../Model/SystemModels";
import { useForceUpdate } from "@fluentui/react-hooks";
import {Link, Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCustomerEnvCard } from "../AdminCustomerEnvCard/AdminCustomerEnvCard";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { Loader } from "../../../Common/Loader/Loader";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { CustomerService } from "../../../../ApplicationCode/Customer/CustomerService";


export const AdminCustomerEnviromentsList : React.FC = () => {
    const adminService=new AdminService();
    const customerService = new CustomerService();
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.Loaded)
    const [loadMessage, setLoadMessage]= useState("");
    const cardRef = useRef<PageContainerBase>(null); 
    const [customerId,setCustomerId] = useState(HttpHelper.getParameter("customerId"));
    const [name,setName] = useState(HttpHelper.getParameter("name"));
    const navigate = useNavigate();

    const updateCustomerEnvironment = (customerId:number,environmentName:string)=>{        
        adminService.updateCustomerEnvironment(customerId, environmentName).then((resp)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Success,"Aggiornamento ambiente cliente schedulato con successo.");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded);
            ToastService.showMessage(MessageType.Error,"Errore durante la schedulazione dell'aggiornamento ambiente cliente: "+jqXHR.message);
        })
    }
 
    const getTenantByCustomerId = (customerId: number, environmentName: string) => {
       customerService.getCustomerSetupConfigurationFromCustomerId(customerId).then((resp) => {
           if (resp.tenantId)
               window.open("https://businesscentral.dynamics.com/" + resp.tenantId + "/"+environmentName+"");
           else
               ToastService.showMessage(MessageType.Error, "Tenant Id non configurato per il cliente Id: " + customerId);
       })
       .catch((jqXHR) => {
           ToastService.showMessage(MessageType.Error, "Errore nel recupero del Tenant Id: " + jqXHR.message);
       });
   }

    if (!SystemCore.isFinishedDataStatus(dataStatus)){
        return <Loader text={loadMessage}></Loader>;
    }
    else
        return (
            <>
                <PageContainer endpoint={"odata/customer/environment?$filter=customerId eq "+customerId} pageType={SystemPageType.List} title={"Lista ambiente per "+name} cardRef={cardRef} backAllowed updateAllowed>               
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="updateCustomerEnvironments" label="Aggiorna ambiente cliente" iconName="WorkFlow" isPromoted runOnRec onClick={(req:any)=>{
                                setLoadMessage("Aggiornamento ambiente cliente in corso ...");
                                setDataStatus(SystemDataLoadingStatus.ToLoad);
                                updateCustomerEnvironment(req.customerId,req.environmentName);
                            }}></ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry name="openEnvironmentApps" label="App ed Extensions" iconName="AllApps" runOnRec onClick={(req:any)=>{
                                    navigate("/app/admin/related/customerApp?customerId="+req.customerId+"&environmentName="+req.environmentName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="OpenProcessModule" label="Moduli processo" iconName="EntitlementPolicy" runOnRec  onClick={(req:any)=>{
                                    navigate("/app/admin/related/bcnCustomerProcessModule?customerId="+req.customerId+"&envName="+req.environmentName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="openBcEnvironment" label="Apri Ambiente Business Central" runOnRec iconName="ServerEnviroment" onClick={(req: any) => {
                                getTenantByCustomerId(req.customerId, req.environmentName);
                                }} />
                            <ActionEntry name="OpenBusinessCentralCompany" label="Società" iconName="CompanyDirectory" runOnRec onClick={(req:any)=>{
                                    navigate("/app/admin/related/businessCentralCompany?customerId="+req.customerId+"&envName="+req.environmentName);
                                }}>
                            </ActionEntry>
                            <ActionEntry name="RapidStartPackages" label="Pacchetti Rapidstart" iconName="PageData" runOnRec onClick={(req:any)=>{
                                    navigate("/app/admin/related/packages?customerId="+req.customerId+"&environmentName="+req.environmentName);
                                }}>
                            </ActionEntry>
                            <ActionEntry iconName="DatabaseSync" label="Richieste Dati (DSY)" name="GetDataFromDsyBc" runOnRec onClick={(req:any)=>{
                                navigate("/app/admin/related/dsyDataRequest?customerId="+req.customerId+"&envName="+req.environmentName);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="OpenQueuedOperations" label="Coda operazioni" iconName="BuildQueue" runOnRec onClick={(req:any)=>{
                                    const pageParams: string = btoa(JSON.stringify({openBy:'environment',customerId:customerId,customerName:name,environmentName:req.environmentName}));
                                    navigate('/app/admin/related/queuedOperations?pageParams='+pageParams);
                                }}>
                            </ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name="Nome Ambiente" fieldName="environmentName" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry name="Versione" fieldName="platformVersion" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Update Automatico" fieldName="automaticUpdate" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Visibile" fieldName="visibile" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name="Stato" fieldName="environmentDeployStatus" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                            if (item.environmentDeployStatus === 0)
                                return "NotStarted"
                            else if (item.environmentDeployStatus === 1)
                                return "InProgress"
                            else if (item.environmentDeployStatus === 2)
                                return "Completed"
                            else if (item.environmentDeployStatus === 3)
                                return "InError"
                            else if (item.environmentDeployStatus === 4)
                                return "Updgrading"
                        }} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminCustomerEnvCard ref={cardRef} />
            </>
        );
}
