import { useContext, useEffect } from "react";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { useLocation, useNavigate } from "react-router-dom";

// initializeIcons();

export const Dispatcher: React.FC = () => {
    const sessionStateContext: ISessionStateContext = useContext(SessionStateContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!sessionStateContext.isAuthenticated) {
            const currentParams = new URLSearchParams(location.search);
            navigate(`/login?${currentParams.toString()}`);
        }
        else {
            navigate("/app");
        }
    }, []);

    return <></>
}
