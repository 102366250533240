import { useContext, useEffect, useState } from "react";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { t } from "i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { Loader } from "../Loader/Loader";
import { BaseButton, Button, Icon, Image, ImageFit, ITextFieldProps, ITextProps, Link, mergeStyles, PrimaryButton, Stack, Text, TextField } from "@fluentui/react";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { SystemCore } from "../../../Core/System/SystemCore";
import LogoSoSmart from '../../../Assets/Images/logoSoSmart.png';
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { UserCredential } from "../../../Model/SecurityModel";
import { HttpHelper } from "../../../Core/Http/HttpHelper";

// initializeIcons();

const loginBoxPageContainer = mergeStyles([{
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#e9ecef !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}]);

const loginBoxContainer = mergeStyles([{
    width: '22%',
    maxHeight: '850px',
    minWidth: '425px',
    backgroundColor: 'white',
    border: '0 solid rgba(0,0,0,.125)',
    boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)',
    borderRadius: 8,
    borderTop: '5px solid #007bff'
}]);

const loginLogoContainer = mergeStyles([{
    width: '100%',
    borderBottom: '2px solid rgba(0,0,0,.125)'
}]);

const registerLinkClassName = mergeStyles([{
    width: '100%',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #d4d4d4'
}]);

export interface ILoginForm {
    username: string;
    password: string;
    userNameErrorMessage?: string;
    passwordErrorMessage?: string;
    saveInProgress: boolean;
    showPrivateLoginBox: boolean;
    showPasswordDismiss: boolean;
    confirmButtonDisabled: boolean;
    handleUsernameChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void;
    handlePasswordChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void;
    handleLoginClick: (ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton | Button, MouseEvent>) => void;
    handleCheckUserType: () => void;
    handleUserResetPassword: () => void;
}

const LoginForm: React.FC<ILoginForm> = (props) => {
    let privateFormContainer = mergeStyles([{
        padding: 20,
        paddingTop: 0,
        display: 'block'
    }]);

    let privateLoginBox = mergeStyles([{
        display: (props.showPrivateLoginBox) ? 'block' : 'none',
    }]);
    
    let confirmButtonContainer = mergeStyles([{
        display: (props.confirmButtonDisabled) ? 'none' : 'block',
        marginTop: 12
    }]);

    let passwordDismissContainer = mergeStyles([{
        display: (props.showPasswordDismiss) ? 'block' : 'none',
        marginTop: 10,
        textAlign: "center"
    }]);

    const loginButtonClass = mergeStyles([{
        width: '100%',
        marginTop: 25
    }]);
    
    const onRenderDescription = (props?: ITextFieldProps | undefined, defaultRender?: ((props?: ITextFieldProps | undefined) => JSX.Element | null) | undefined): JSX.Element | null => {
        return (
            <Text variant="xSmall" style={{fontWeight: "bold"}}>
                {props!.description}
            </Text>
        );
    };
    
    return (
        <>
            <Stack.Item className={privateFormContainer}>
                <Stack verticalAlign="start" verticalFill horizontalAlign="center" style={{ marginTop: 6, marginBottom: 6 }}>
                    <Text variant="xLarge" style={{ fontWeight: 700, letterSpacing: 1.5 }}>{t('common:LoginBox:welcome')!}</Text>
                    <Text variant="xSmall">{t('common:LoginBox:welcome2')!}</Text>
                </Stack>
                <TextField
                    label="Account"
                    value={props.username}
                    onChange={props.handleUsernameChange}
                    errorMessage={props.userNameErrorMessage}
                    invalid={(props.userNameErrorMessage! > '')}
                    placeholder={t('common:LoginBox:emailText')!}
                />
                <Stack.Item className={privateLoginBox}>
                    <TextField
                        type='password'
                        label={t('common:LoginBox:passwordText')!}
                        value={props.password}
                        onChange={props.handlePasswordChange}
                        onRenderDescription={onRenderDescription}
                        errorMessage={props.passwordErrorMessage}
                        invalid={(props.passwordErrorMessage! > '')}
                        placeholder={t('common:LoginBox:passwordText2')!}
                        canRevealPassword />
                    <PrimaryButton style={{ borderRadius: 20 }} toggle text={t('common:LoginBox:primaryButtonText2')!} allowDisabledFocus className={loginButtonClass} onClick={props.handleLoginClick} />
                </Stack.Item>
                <Stack.Item className={confirmButtonContainer}>
                    <PrimaryButton style={{ borderRadius: 20 }} disabled={props.saveInProgress || props.confirmButtonDisabled} toggle text={t('common:LoginBox:primaryButtonText3')!} allowDisabledFocus className={loginButtonClass} onClick={props.handleCheckUserType} />
                </Stack.Item>
                <Stack.Item className={passwordDismissContainer}>
                    <Stack.Item>
                        <Text style={{ color: "#A4262C"}}>{t('common:LoginBox:wrongPassword')!}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Link style={{ position: "relative", marginTop: 5 }} onClick={props.handleUserResetPassword} underline>
                            {t('common:LoginBox:forgotPasswordLinkText')!}
                            <Icon style={{ position: "absolute", left: 65, top: 3 }} iconName="DoubleChevronRight" />
                        </Link>
                    </Stack.Item>
                </Stack.Item>
            </Stack.Item>
        </>
    );
}

const RegisterLink: React.FC = () => {
    return (
        <Stack.Item>
            <Stack horizontal key="registerLink" className={registerLinkClassName}>
                <Stack.Item>
                    <Text key={"accountNeeded"} variant={"small" as ITextProps["variant"]}>
                        {t("common:LoginBox:accountNeeded")!}
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Text key={"login"} variant={"small" as ITextProps["variant"]}>
                        <Link style={{ position: "relative" }} href="register" underline>
                            {t("common:LoginBox:register")!}
                            <Icon style={{ position: "absolute", left: 60, top: 3 }} iconName="DoubleChevronRight" />
                        </Link>
                    </Text>
                </Stack.Item>
            </Stack>
        </Stack.Item>
    );
};

const LoginBox = () => {
    const sessionStateContext: ISessionStateContext = useContext(SessionStateContext);
    const [showLoader, setShowLoader] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState(t('common:LoginBox:loader:message')!);
    const [logo, setLogo] = useState<string>();
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [saveInProgress, setSaveInProgress] = useState(false);
    const navigate = useNavigate();
    const [showPrivateLoginBox, setShowPrivateLoginBox] = useState(false);
    const [showPasswordDismiss, setShowPasswordDismiss] = useState(false);
    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
    
    const getRequestedResource = () => {
        let requestedResource = HttpHelper.getParameter("resource");
        if ((requestedResource !== undefined) && (requestedResource !== ""))
            return atob(requestedResource);
        
        return undefined;
    }

    const getRedirectUrl = (login_hint?: string) => {
        let redirectUrl = "https://login.microsoftonline.com/68ddd42b-912d-4bd9-9c17-7c20ab9aaa7d/oauth2/authorize?client_id=d459d8e8-09d5-4ac1-9075-f31eb980c70c&response_type=id_token&redirect_uri=" + window.location.origin + "/ms/afterauth&response_mode=fragment&scope=https://graph.microsoft.com/.default&nonce=678910";
        
        if (login_hint)
            redirectUrl += '&login_hint=' + login_hint

        let callbackUrl = getRequestedResource();
        if (callbackUrl)
            redirectUrl += "&state=" + btoa(callbackUrl);

        return redirectUrl;
    }

    const getEmailByParams = () => {
        let emailParam = HttpHelper.getParameter("email");
        console.log(emailParam);
        if (emailParam){
            console.log(atob(emailParam));
            return atob(emailParam);
        }

        return undefined;
    }
    
    const checkAuthenticationParameter = () => {
        var url = new URL(window.location.toString());
        var ctx = url.searchParams.get("ctx");
        
        if (ctx != null) {
            ctx = atob(ctx);
            
            let systemCore: SystemCore = new SystemCore();
            systemCore.authenticateUserFromParameter(ctx)
                .then((resp) => {
                    sessionStateContext.isAuthenticated = true;
                    navigate("/app");
                })
                .catch((err) => {
                    ctx = null;
                    ToastService.showMessage(MessageType.Error, err);
                    navigate("/login");
                });
        }
    };
    
    useEffect(() => {
        if (dataStatus === SystemDataLoadingStatus.ToLoad) {
            setLoaderMessage(t('common:LoginBox:loader:message')!);
            setShowLoader(true);
            setDataStatus(SystemDataLoadingStatus.Loading);

            checkAuthenticationParameter();
            
            let systemCore = new SystemCore();
            
            if(systemCore.getUserToken() === "" && !sessionStateContext.isAuthenticated)
            {
                systemCore.authenticateUserFromRefreshToken().then(()=>{
                    sessionStateContext.isAuthenticated = true;
                    navigate("/app");
                })
            }

            SystemCore.getSystemAppPublicInfo(window.location.origin)
                .then((resp:any) => {
                    document.title = resp.appTitle;
                    SystemCore.setFavicons('data:image/png;base64,'+resp.appIcon);
                    setLogo('data:image/png;base64,'+resp.appLogo);
                    setShowLoader(false);
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                })
                .catch((err) => {
                    setShowLoader(false);
                    setLogo(LogoSoSmart);
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                });
            
            let userNameParam = getEmailByParams();
            if (userNameParam) {
                setUsername(userNameParam);
                handleCheckUserType(userNameParam);
            }
        }
    }, [dataStatus])

    const resetState = () => {
        setUsernameErrorMessage("");
        setPasswordErrorMessage("");
        setConfirmButtonDisabled(false);
        setShowPrivateLoginBox(false);
    }
    
    const handleUsernameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        setUsername(newValue!);
        if (!newValue!)
            setUsernameErrorMessage(t('common:LoginBox:emailMandatory')!);
        else
            setUsernameErrorMessage("");
    }

    const handlePasswordChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        setPassword(newValue!);
        if (!newValue)
            setPasswordErrorMessage(t('common:LoginBox:passwordMandatory')!);
        else
            setPasswordErrorMessage("");
    }

    const handleCheckUserType = (email: string) => {
        if (!email) {
            setUsernameErrorMessage(t('common:LoginBox:emailMandatory')!);
            return;
        }
        
        setSaveInProgress(true);
        
        CommonFunctions.getUserType(email)
            .then((resp) => {
                if (resp.actionType === "Redirect" ) {
                    ToastService.showMessage(MessageType.Information, t('common:LoginBox:userNotRegistered')!);
                    setTimeout(() => {
                        window.location.replace(resp.message);
                    }, 3000);
                }
                else if (resp.actionType === "SetPassword") {
                    window.location.replace(resp.message);
                }
                else if (resp.userType === "AAD") {
                    window.location.replace(getRedirectUrl(email));
                    setSaveInProgress(false);
                }
                else if (resp.userType === "Private") {
                    setConfirmButtonDisabled(true);
                    setShowPrivateLoginBox(true);
                    setSaveInProgress(false);
                }
                else if (resp.userType === "") {
                    setUsernameErrorMessage(t("common:LoginBox:registerText")!);
                    setSaveInProgress(false);
                }
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, err.message);
                setSaveInProgress(false);
            });
    }
    
    const handleLoginClick = (ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton | Button, MouseEvent>) => {
        if (!username) {
            setUsernameErrorMessage(t('common:LoginBox:emailMandatory')!);
            return;
        }
        if (!password) {
            setPasswordErrorMessage(t('common:LoginBox:passwordMandatory')!);
            return;
        }

        ev.preventDefault();
        
        setLoaderMessage(t('common:LoginBox:loader:text')!);
        setSaveInProgress(true);
        setShowLoader(true);
        
        let systemCore: SystemCore = new SystemCore();
        let credential: UserCredential = {
            username: username,
            password: password
        };

        systemCore.checkUserPassword(credential)
            .then((resp) => {
                if (!resp)
                    {
                        setShowPasswordDismiss(true);
                        setPassword("");
                        setShowLoader(false);
                        setSaveInProgress(false);
                    }
                else {        
                    systemCore.authenticateUser(credential)
                    .then((resp) =>{
                        sessionStateContext.isAuthenticated = true;
                        let requestedResource = getRequestedResource();
                        if (requestedResource)
                            navigate(requestedResource);
                        else
                            navigate("/app");
                        
                        return true;
                    })
                    .catch((resp) => {
                        ToastService.showMessage(MessageType.Error, resp.error);
                        setUsername("");
                        setPassword("");
                        setShowLoader(false);
                        setSaveInProgress(false);
                        resetState();
                        
                        return false;
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }
    
    const handleUserResetPassword = (email: string) => {
        setSaveInProgress(true);
        
        let promise = CommonFunctions.handlePasswordReset(email)
        .then(() => {
            setSaveInProgress(false);
        })
        .catch((err) => {
            setSaveInProgress(false);
        });

        ToastService.showPromiseMessage(promise, t('common:LoginBox:Text00002lbl')!, t('common:LoginBox:Text00001lbl')!, t('common:LoginBox:Text00003lbl')!)
    }
    
    if (!sessionStateContext.isAuthenticated) {
        if (!showLoader) {
            return (
                <Stack className={loginBoxPageContainer}>
                    <Stack className={loginBoxContainer}>
                        <Stack.Item className={loginLogoContainer}>
                            <Image src={logo} imageFit={ImageFit.cover} style={{
                                padding: 5,
                                marginTop: 4,
                                marginBottom: 8
                            }} />
                        </Stack.Item>
                        <LoginForm
                            username={username}
                            password={password}
                            userNameErrorMessage={usernameErrorMessage}
                            passwordErrorMessage={passwordErrorMessage}
                            saveInProgress={saveInProgress}
                            showPrivateLoginBox={showPrivateLoginBox}
                            showPasswordDismiss={showPasswordDismiss}
                            confirmButtonDisabled={confirmButtonDisabled}
                            handleUsernameChange={handleUsernameChange}
                            handlePasswordChange={handlePasswordChange}
                            handleCheckUserType={() => {handleCheckUserType(username)}}
                            handleLoginClick={handleLoginClick}
                            handleUserResetPassword={() => {handleUserResetPassword(username)}}
                        />
                        <RegisterLink />
                    </Stack>
                </Stack>
            )
        }
        else
            return <Loader text={loaderMessage} />
    }
    else
        return <Navigate to='/app' />
}

export default LoginBox;