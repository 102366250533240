import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import '../node_modules/react-toastify/dist/ReactToastify.css';
import { createApplicationRoutes } from './Core/Routes/ApplicationRoutes';
import { SessionStateContextProvider } from './Core/State/SessionStateContext';

// initializeIcons();

export const App: React.FunctionComponent = () => {
  
  let applicationRoutes = createApplicationRoutes();

  return (
    <BrowserRouter basename='/'>
      <SessionStateContextProvider>
        {applicationRoutes}
        
        <ToastContainer 
          autoClose={5000}
          position="bottom-right"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover /> 
      </SessionStateContextProvider>
    </BrowserRouter>
  );
};
