import { CommandBar, CommandBarButton, IButtonProps, IButtonStyles, ICommandBarItemProps, ICommandBarStyles, IComponentAs, IContextualMenuItem, IContextualMenuItemStyleProps, IContextualMenuItemStyles, IContextualMenuProps, IContextualMenuStyleProps, IContextualMenuStyles, IIconStyleProps, IIconStyles, IStyleFunctionOrObject, Image, ImageFit, Layer, Stack, mergeStyleSets } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MenuEntry, SystemDataLoadingStatus, SystemLanguage } from "../../../Model/SystemModels";
import { FeedbackDialog } from "../../Docs/Old/Docs";
import { FeatureSearchBox } from "../FeatureSearchBox/FeatureSearchBox";
import i18next, { t } from "i18next";
import './ApplicationNavBar.css';
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import copilotIcon from "../../../Assets/Images/copilot.png";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerVersion } from "../../../Model/CustomerModels";
import { ApplicationOutletContext, useOutlet } from "../ApplicationContainer/ApplicationContainer";

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

const _styles: ICommandBarStyles = {
    root: {
        backgroundColor: 'inherit'
    }
};

const _iconButtonStyleDark: IButtonStyles = {
    root:{
        backgroundColor: "black",
        borderRadius: "0",
        color: "white"
    },
    rootHovered:{
        backgroundColor: "black",
        color: "#008089 !important"
    },
    rootPressed:{
        backgroundColor: "black",
        color: "#015D63 !important"
    },
    icon:{
        backgroundColor: "black",
        color: "white"
    },
    iconHovered:{
        backgroundColor: "black",
        color: "#008089 !important"
    },
    iconPressed:{
        backgroundColor: "black",
        color: "#015D63 !important"
    },
    rootExpanded:{
        backgroundColor: "black",
        color: "white"
    },
    rootExpandedHovered: {
        backgroundColor: "black",
        color: "#015D63 !important"
    },
    menuIcon: {
        backgroundColor: "black",
        color: "white",
    },
    menuIconHovered: {
        backgroundColor: "black",
        color: "#008089",
    },
    menuIconPressed: {
        backgroundColor: "black",
        color: "#015D63",
    },
    menuIconExpanded:{
        backgroundColor: "black",
        color: "white !important"
    },
    menuIconExpandedHovered:{
        backgroundColor: "black",
        color: "#008089 !important"
    }
};

const _iconButtonStyleLight: IButtonStyles = {
    root:{
        backgroundColor: "white",
        borderRadius: "0",
        color: "#2A7DE1"
    },
    rootHovered:{
        backgroundColor: "white",
        color: "#184e8f !important"
    },
    rootPressed:{
        backgroundColor: "white",
        color: "#015D63 !important"
    },
    icon:{
        backgroundColor: "white",
        color: "#2A7DE1"
    },
    iconHovered:{
        backgroundColor: "white",
        color: "#184e8f !important"
    },
    iconPressed:{
        backgroundColor: "white",
        color: "#184e9f !important"
    },
    rootExpanded:{
        backgroundColor: "white",
        color: "#2A7DE1"
    },
    rootExpandedHovered: {
        backgroundColor: "white",
        color: "#184e8f !important"
    },
    menuIcon: {
        backgroundColor: "white",
        color: "#2A7DE1",
    },
    menuIconHovered: {
        backgroundColor: "white",
        color: "#184e8f",
    },
    menuIconPressed: {
        backgroundColor: "white",
        color: "#184e9f",
    },
    menuIconExpanded:{
        backgroundColor: "white",
        color: "#2A7DE1 !important"
    },
    menuIconExpandedHovered:{
        backgroundColor: "white",
        color: "#184e8f !important"
    }
};

const _subMenuStyles: IStyleFunctionOrObject<IContextualMenuItemStyleProps, IContextualMenuItemStyles> = {
    root: {
        color: "black",
        ':hover': {
            color: "#008089 !important"
        },
        ':active': {
            color: "#015D63 !important"
        }
    },
    icon: {
        color: "black !important"
    }
}

const styleSet = mergeStyleSets({
    contentLight: {
        backgroundColor: "white",
        borderBottom: "1px solid #CFD0D1",
        color: "black",
        lineHeight: "50px",
        fontSize: "18px",
        display: "inline-block",
        width: "100%"
    },
    contentDark: {
        backgroundColor: "black",
        borderBottom: "1px solid black",
        color: "white",
        lineHeight: "50px",
        fontSize: "18px",
        display: "inline-block",
        width: "100%"
    }
});
export interface IApplicationNavBarProps {
    onOpenGuidePanel?: () => void;
    onHandleContainerStyle?: (isOpen: boolean) => void;
    docsUrl?: string;
}


export const ApplicationNavBar : React.FC<IApplicationNavBarProps> = (props) => {
    const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
    const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false);
    const [languageDataStatus, setLanguageDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const [languageMenuProps, setLanguageMenuProps] = useState<IContextualMenuProps>({items: []});

    const sessionStateContext = useContext(SessionStateContext);
    const navigate = useNavigate();
    const systemCore = new SystemCore();

    const generateStyles = (component: string): string =>{
        let contentStyle = "";

        if(window.location.pathname.indexOf("app") > 0)
            contentStyle = styleSet.contentDark;
        else
            contentStyle = styleSet.contentLight;
        
        switch(component){
            case "content":
                return contentStyle;
            default: 
                return "";
        }
    }

    function generateCommandBarItemStyle(isSubMenu: boolean): Partial<IButtonStyles | IStyleFunctionOrObject<IContextualMenuStyleProps, IContextualMenuStyles> | IStyleFunctionOrObject<IIconStyleProps, IIconStyles>> {
        if(isSubMenu){
            if(window.location.pathname.indexOf("app") > 0)
                return _subMenuStyles;
            
            return "";
        }
        else{
            if(window.location.pathname.indexOf("app") > 0)
                return _iconButtonStyleDark;
            
            return _iconButtonStyleLight;
        }
    }

    const CustomButton: React.FC<IButtonProps> = (props) => {
        return <CommandBarButton {...props} onClick={props.onClick} styles={generateCommandBarItemStyle(false)} />;
    };

    const GetfarItems = () => {
        let items: ICommandBarItemProps[] = [
            {
              key: 'Language',
              text: i18next.resolvedLanguage.toUpperCase(),
              ariaLabel: 'Language',
              subMenuProps: languageMenuProps,
              disabled: languageDataStatus !== SystemDataLoadingStatus.Loaded,
            },
            {
              key: 'Logout',
              text: t('common:ApllicationNavBar:Text000002Action')!,
              ariaLabel: 'Logout',
              iconOnly: true,
              ariaDescribedBy: 'true',
              iconProps: { iconName: 'PowerButton' },
              onClick: () => systemCore.logOutUser(sessionStateContext, navigate),
            }
        ];

        if (enableIcon("Chatbot"))
            items.unshift(
                {
                    key: 'Chatbot',
                    text: 'Chatbot',
                    ariaLabel: 'Chatbot',
                    iconOnly: true,
                    tooltipHostProps: {
                        styles: {
                            root: {
                                display: 'flex',
                                alignItems: 'center',
                            }
                        }
                    },
                    iconProps: {
                        styles: {
                            root: {
                                height: 25,
                            }
                        },
                        imageProps: {
                            src: copilotIcon,
                            alt: 'Chatbot',
                            height: 25,
                            width: 25,
                            imageFit: ImageFit.cover
                        }
                    },
                    onClick: () => {
                        props.onOpenGuidePanel!();
                    }
                }
            );

        if (enableIcon("Guide")){
            items.unshift(
                {
                    key: 'Guide',
                    text: t('common:ApllicationNavBar:Text000003Action')!,
                    ariaLabel: 'Help',
                    iconOnly: true,
                    ariaDescribedBy: 'true',
                    iconProps: { iconName: 'Help' },
                    onClick: () => {
                        navigate(props.docsUrl!)
                    }
                }
            )
        }

        if (enableIcon("Search"))
            items.unshift(
                {
                    key: 'Search',
                    text: t('common:ApllicationNavBar:Text000004Action')!,
                    ariaLabel: 'Search',
                    iconOnly: true,
                    ariaDescribedBy: 'true',
                    iconProps: { iconName: 'Search' },
                    onClick: () => {
                        setShowSearchBox(true);
                    }
                }
            )

        return items;
    };

    const handlePageSelection = (selected: MenuEntry) => {
        setShowSearchBox(false);

        navigate(selected.endpoint);
    }

    const changeLanguage = (language: string) => {
        i18next.changeLanguage(language);
        
        if(sessionStateContext.currentUserSettings.userLanguageCode !== systemCore.formatLanguage(i18next.language))
        systemCore.setUserLanguageCode(sessionStateContext.currentUserSettings).then(()=>{
            sessionStateContext.currentUserSettings.userLanguageCode = systemCore.formatLanguage(i18next.language);
            window.location.reload();
        })
        .catch((jqXHR)=>{
            window.location.reload()
        });
    }

    const enableIcon = (key: string) : boolean => {

        switch(key){
            case "Search":
                if(window.location.pathname.indexOf("demo") > 0 || 
                   window.location.pathname.indexOf("docs") > 0) 
                   return false;
                   
                return true;
            case "Guide":
                if(!sessionStateContext.currentApp.helpPageEnable || 
                    window.location.pathname.indexOf("docs") > 0)
                    return false;

                return true;
            case "Chatbot":
                if(!sessionStateContext.currentApp.showChatBot)
                    return false;

                return true;
            default: return true;
        }
    }

    const getLanguages = () => {
        setLanguageDataStatus(SystemDataLoadingStatus.Loading);

        let availableLanguages:IContextualMenuItem[] = [];
        CommonFunctions.getEnabledLanguage().then((resp)=>{
            
            resp.forEach((language:SystemLanguage)=>{
                availableLanguages.push(
                    {
                      key: language.code.toUpperCase(),
                      text: language.name,
                      iconProps: {iconName: 'Globe' },
                      itemProps: { styles: generateCommandBarItemStyle(true) },
                      disabled: i18next.resolvedLanguage.toUpperCase() === language.code.toUpperCase(),
                      onClick: () =>{changeLanguage(language.code.toLowerCase());}
                    }
                );
            })

            setLanguageMenuProps({items: availableLanguages});

            setLanguageDataStatus(SystemDataLoadingStatus.Loaded);
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error,t('common:ApllicationNavBar:Text000001Err'));
            setLanguageDataStatus(SystemDataLoadingStatus.Error);
        })
    }

    useEffect(()=>{
        if(languageDataStatus === SystemDataLoadingStatus.ToLoad)
            getLanguages();
    },[languageDataStatus]);

    return (
        <Layer>
            <Stack className={generateStyles("content")} id="applicationNavbar">
                <Stack.Item 
                    style={{float: 'left', paddingLeft: '50px', paddingRight: 8,cursor: 'pointer'}} 
                    onClick={() => {
                        props.onHandleContainerStyle!(false);
                        navigate(sessionStateContext.currentApp.homepageUrl);
                    }}
                >
                    <Image src={"data:image/png;base64," + sessionStateContext.currentApp.appIcon} className="brand-image" imageFit={ImageFit.centerContain} style={{padding: 5, paddingLeft: 0, marginTop: 8}}></Image>
                </Stack.Item>
                <label 
                    className={generateStyles("label")} 
                    onClick={() => {
                        props.onHandleContainerStyle!(false);
                        navigate(sessionStateContext.currentApp.homepageUrl);
                    }}
                >
                    {sessionStateContext.currentApp.title}
                </label>
                <Stack.Item style={{float: 'right', paddingRight: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px'}}>
                    <CommandBar
                        items={[]}
                        overflowItems={[]}
                        overflowButtonProps={overflowProps}
                        buttonAs={CustomButton}
                        farItems={GetfarItems()}
                        ariaLabel="Inbox actions"
                        styles = {_styles}
                    />
                </Stack.Item>
            </Stack>
            <FeatureSearchBox showDialog={showSearchBox}
                              onSelectFeatute={handlePageSelection}
                              onCancelModal={() => setShowSearchBox(false)}/>
            <FeedbackDialog show={showFeedbackDialog} title={t('docs:FeedbackDialog:title')!} onConfirm={() => setShowFeedbackDialog(false)} onDismiss={() => setShowFeedbackDialog(false)} />
        </Layer>
    );

}