import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardField } from "../../../Common/Template/Card/CardField";

export const AdminUserSettingsCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda impostazioni utente"} endpoint="odata/user/settings" ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="userId" type={SystemFieldType.Number} label="Id utente" readonly />
                <CardField name="hideCustomerCommonGuideTour" type={SystemFieldType.Boolean} label="Nascondi tour guidato" readonly />
                <CardField name="userLanguageCode" type={SystemFieldType.String} label="Codice lingua utente" readonly />
                <CardField name="bookingCalendarUrl" type={SystemFieldType.String} label="URL calendario booking" />
                <CardField name="jobTitle" type={SystemFieldType.String} label="Job Title" />
                <CardField name="pictureUrl" type={SystemFieldType.String} label="URL Picture" />
            </CardGroup> 
        </PageContainer>
    );
});