import React, {useRef, useState} from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { AdminEmailTemplateLanguageCard } from "../AdminEmailTemplateLanguageCard/AdminEmailTemplateLanguageCard";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { CommonUploadFileDialog } from "../../../Common/CommonUploadFileDialog/CommonUploadFileDialog";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";

export const AdminEmailTemplateLanguageList : React.FC = () => {
    const [saveInProgress,setSaveInProgress] = useState<boolean>(false);
    const [showUploadDialog,setShowUploadDialog] = useState<boolean>(false);
    const [templateCode,setTemplateCode] = useState<string>("");
    const [templateLanguageReferenceId,setTemplateLanguageReferenceId] = useState<string>("");
    const ref = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    const navigate = useNavigate();

    const downloadMailTemplate = (code:string,name:string)=>{
        adminService.downloadMailTemplate(code).then((srvData)=>{
            Object.assign(document.createElement('a'),{ href: 'data:text/html;charset=UTF-8,'+encodeURIComponent(srvData), download:name}).click();
            ToastService.showMessage(MessageType.Information,"Download completato con successo")
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error,"Errore durante il download del file: "+jqXHR.message);
        })
    };

    const uploadMailTemplate = (file:File)=>{
        setSaveInProgress(true)

        adminService.uploadMailTemplate(templateCode,templateLanguageReferenceId,file).then((resp)=>{
            setShowUploadDialog(false);
            setSaveInProgress(false)
            ToastService.showMessage(MessageType.Success,"Upload completato.");
        })
        .catch((jqXHR)=>{
            setSaveInProgress(false)
            ToastService.showMessage(MessageType.Error,"Errore: Impossibile caricare il template.");
        })
    }

    const buildEndpoint = (): string => {
        let url = '/odata/email/template/language';
        const templateCode = HttpHelper.getParameter("templateCode");
        if((templateCode !== "") && (templateCode !== null))
            url += "?$filter=emailTemplateCode eq '" + templateCode + "'";

        return url;
    }

    return (
        <>
            <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title="Email Template Language" backAllowed insertAllowed deleteAllowed updateAllowed cardRef={ref}>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenEmailAttachment" label="Allegati email" iconName="Attach" isPromoted runOnRec onClick={(req:any) => {
                            navigate("/app/admin/administrative/emailAttachment?templateCode="+req.emailTemplateCode+"&legalEntityCode=" + req.legalEntityCode+"&distributorCode=" + req.distributorCode+"&partnerBusinessContractCode=" + req.partnerBusinessContractCode+ "&languageCode="+ req.languageCode);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="uploadFile" label="Carica Template" iconName="Upload" isPromoted runOnRec onClick={(req:any)=>{
                                setTemplateCode(req.emailTemplateCode);
                                setTemplateLanguageReferenceId(req.systemId);
                                setShowUploadDialog(true)
                            }}>
                        </ActionEntry>
                        <ActionEntry name="downloadFile" label="Scarica Template" iconName="Download" isPromoted runOnRec onClick={(req:any)=>{
                                downloadMailTemplate(req.systemId,req.fileName);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>               
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice template email" fieldName="emailTemplateCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Legal entity" fieldName="legalEntityCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Distributore" fieldName="distributorCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Partner business contract" fieldName="partnerBusinessContractCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Lingua" fieldName="languageCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Nome file" fieldName="fileName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Pacchetto blob" fieldName="packageBlob" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Test email" fieldName="emailTest" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Soggetto" fieldName="subject" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Usa Dynamics 365 Marketing" fieldName="useDynamics365Marketing" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Ignora allegati" fieldName="ignoreAttachments" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Non tracciare" fieldName="doNotTracking" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Codice struttura" fieldName="structureCode" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminEmailTemplateLanguageCard title="Nuovo Template" ref={ref} />
            <CommonUploadFileDialog
                title="Carica Template"
                subText="Seleziona il file da caricare"
                hidden={showUploadDialog}
                txtLabel="File"
                ext=".html,.htm"
                saveInProgress={saveInProgress}
                onSuccess={uploadMailTemplate}
                onDismiss={()=>{setShowUploadDialog(false)}}
            />
        </>
    );
}