import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { SystemPageType, Ticket, TicketInfo } from "../../../Model/SystemModels";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { CustomCustomerIncidentCard } from "../CustomerIncidentCard/CustomCustomerIncidentCard";

export const CustomerIncidentList : React.FC = () => {
    const customerInfoContext = useContext(CustomerInformationContext);
    const sessionInfoContext: ISessionStateContext = useContext(SessionStateContext);
    const [showCreateCard, setShowCreateCard] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [ticket, setTicket] = useState<Ticket>(new Ticket());
    
    const currentPage = useRef<PageContainerBase>(null);
    const currentRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    const buildEndpoint = () : string => {
        
        const customerId = customerInfoContext.setupConfig.customerId;

        const endpoint: string = "/odata/crm/user/incident?$filter=customerId eq "+customerId+"&$orderby=stateCode, createdOn desc";
                
        return endpoint;
    }
    
    const onSaveIncident = (record: Ticket) => {        
        setSaveInProgress(true);
        if (record.ticketInfo.title === undefined || record.ticketInfo.title === ""){
            setSaveInProgress(false);
            ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerIncidentList:CustomerIncidentCard:CardGroup:CardField:validation:title')!);
        }
        else if (record.ticketInfo.description === undefined || record.ticketInfo.description === ""){
            setSaveInProgress(false);
            ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerIncidentList:CustomerIncidentCard:CardGroup:CardField:validation:description')!);
        }
        else{            
            let customerService = new CustomerService();
            customerService.createIncident(record)
            .then((resp) => {
                setTicket(new Ticket());
                setShowCreateCard(false);
                setSaveInProgress(false);
                currentPage.current?.reloadData();
                ToastService.showMessage(MessageType.Success, t('customerCenter:CustomerIncidentList:ticketSuccesfullyCreated')!);
            })
            .catch((resp) => {
                setSaveInProgress(false);
                ToastService.showMessage(MessageType.Error, resp.message);
            });
        }
    }

    const onNewRecord = (sourceTitle?: string, sourceAddress?: string, lastError?: string, origin?: string) => {
        let customerService = new CustomerService();
        let defaulTicketType: number = 10;
        customerService.getDefaultIncidentType().then((resp)=>{
            defaulTicketType = resp.entryNo;
            customerService.getCurrentUser(sessionInfoContext.currentUser.userId)
                .then((resp) => {
                let currentTicket = new Ticket();
                currentTicket.tenantId = customerInfoContext.setupConfig.tenantId;
                currentTicket.userId = sessionInfoContext.currentUser.userId;

                currentTicket.ticketInfo = new TicketInfo();
                currentTicket.ticketInfo.attachments = [];

                currentTicket.ticketInfo.ticketType = defaulTicketType;
                currentTicket.ticketInfo.contactemail = resp.contactEmail;

                if (!currentTicket.ticketInfo.contactemail)
                    currentTicket.ticketInfo.contactemail =
                    sessionInfoContext.currentUser.email;

                if (sourceTitle !== undefined && sourceTitle > "") {
                    currentTicket.ticketInfo.title = sourceTitle;
                    currentTicket.ticketInfo.sourceTitle = sourceTitle;
                }

                if (sourceAddress !== undefined && sourceAddress > "")
                    currentTicket.ticketInfo.sourceAddress = sourceAddress;

                if (lastError !== undefined && lastError > "")
                    currentTicket.ticketInfo.lastError = lastError;

                if (origin !== undefined && origin > "")
                    currentTicket.ticketInfo.sourceApplicationOrigin = origin;
                else
                    currentTicket.ticketInfo.sourceApplicationOrigin =
                    window.location.protocol + "//" + window.location.hostname;

                if (resp.crmContactId === "" || resp.crmContactId === undefined)
                    ToastService.showMessage(MessageType.Error, t("customerCenter:CustomerIncidentList:Err00001lbl")!);
                else {
                    setTicket(currentTicket);
                    setShowCreateCard(true);
                }
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerIncidentList:Err00002lbl')!);
            })
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerIncidentList:Err00002lbl')!);
        })
    }

    const onCancel = () =>{
        setShowCreateCard(false);
    }

    useEffect(() => {
        const openCard: boolean = HttpHelper.getParameter('openCard');
        let sourceTitle: string = HttpHelper.getParameter('title');
        let sourceAddress: string = HttpHelper.getParameter('sourceAddress');
        let lastError: string = HttpHelper.getParameter('lastError');
        let origin: string = HttpHelper.getParameter('origin');

        if (openCard && ((sourceTitle !== null) && (sourceTitle !== "")) && ((sourceAddress !== null) && (sourceAddress !== ""))){       
            sourceTitle = atob(sourceTitle);
            sourceAddress = atob(sourceAddress);
            if (lastError !== undefined && lastError > ""){
                lastError = atob(lastError);
            }
            if (origin !== undefined && origin > ""){
                origin = atob(origin);
            }
            if (sourceTitle.includes(" - ")){
                const splitTitle: string[] = sourceTitle.split(" - ");
                sourceTitle = splitTitle.slice(0, splitTitle.length -1).toString();
            }
            
            onNewRecord(sourceTitle, sourceAddress, lastError, origin);
            navigate(window.location.origin + window.location.pathname, {replace: true});
        }
    
        //Help ShortCut
        let shortCutHelp: string = HttpHelper.getParameter('shortCutHelp');
        if ((shortCutHelp !== null) && (shortCutHelp !== "")){
            CommonFunctions.deleteShortCutParameters(navigate);
            onNewRecord();
        }
    }, []);

    return (
        <>
            <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title={t('customerCenter:CustomerIncidentList:title')!} backAllowed insertAllowed onNewRecord={onNewRecord} ref={currentPage} cardRef={currentRef}> 
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name={t('customerCenter:CustomerIncidentList:columns:userDisplayName')!} fieldName="userDisplayName" minWidth={100} maxWidth={150} />
                    <ListHeaderEntry name={t('customerCenter:CustomerIncidentList:columns:title')!} fieldName="title" minWidth={400} maxWidth={450} />
                    <ListHeaderEntry name={t('customerCenter:CustomerIncidentList:columns:createdOn')!} fieldName="createdOn" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name={t('customerCenter:CustomerIncidentList:columns:stateDescription')!} fieldName="stateDescription" minWidth={80} maxWidth={250} />
                    <ListHeaderEntry name={t('customerCenter:CustomerIncidentList:columns:crmStatusDescription')!} fieldName="crmStatusDescription" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t('customerCenter:CustomerIncidentList:columns:crmIncidentTypeName')!} fieldName="crmIncidentTypeName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name={t('customerCenter:CustomerIncidentList:columns:uploadState:description')!} fieldName="uploadState" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.uploadState === 0)
                            return t('customerCenter:CustomerIncidentList:columns:uploadState:onQueue')!
                        else if (item.uploadState === 1)
                            return t('customerCenter:CustomerIncidentList:columns:uploadState:uploaded')!
                        else if (item.uploadState === 2)
                            return t('customerCenter:CustomerIncidentList:columns:uploadState:inError')!
                    }} />
                </PageDefinitionContainer>
            </PageContainer>
            <CustomCustomerIncidentCard showCard={showCreateCard} record={ticket} saveInProgress={saveInProgress} onCancel={onCancel} onSave={onSaveIncident} />
        </>
    );
}