import { useEffect,useState} from "react";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { useLocation, useNavigate } from "react-router-dom";
import { SessionStateContext } from "../../../Core/State/SessionStateContext";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { SystemCore } from "../../../Core/System/SystemCore";
import * as React from 'react';
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";

export interface ICustomerBuyShortcutProps {

}

export const SoSmartShortcut: React.FC<ICustomerBuyShortcutProps> = (props: ICustomerBuyShortcutProps) => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const navigate = useNavigate();
    const location = useLocation();
    const sessionStateContext = React.useContext(SessionStateContext);

    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            setDataStatus(SystemDataLoadingStatus.Loading);

            buildRedirectUrl();

            setDataStatus(SystemDataLoadingStatus.Loaded);
        }

    },[dataStatus]);

    const buildRedirectUrl = () => {
        let ctx = HttpHelper.getParameter('ctx');        
        const currentParams = new URLSearchParams(location.search);
        let redirectUrl = '';

        switch(window.location.pathname) {
            case "/book":
                redirectUrl = "/app/customer/home?shortCutBook=true";
                break;
            case "/buy":
                redirectUrl = "/app/customer/home?shortCutBuy=true";
                break;
            case "/trial":
                redirectUrl = "/app/customer/home?shortCutTrial=true";
                break;
            case "/help":
                redirectUrl = "/app/customer/supportHelper";
                break;
            default:
                redirectUrl = sessionStateContext.currentApp.homepageUrl;
                break;
        }

        if((ctx !== null) && (ctx !== '')){
            if (!sessionStateContext.isAuthenticated)
            {
                let systemCore: SystemCore = new SystemCore();
                ctx = atob(ctx);
                systemCore.authenticateUserFromParameter(ctx)
                    .then((resp) => {
                        sessionStateContext.isAuthenticated = true;
                        navigate(redirectUrl);
                    })
                    .catch((err) => {
                        ToastService.showMessage(MessageType.Error, err);
                        navigate(redirectUrl);
                    });
            }
            else
                navigate(redirectUrl);
        }
        else
            if (sessionStateContext.isAuthenticated)
                navigate(redirectUrl);
            else {
                if (redirectUrl.indexOf("?") >= 0)
                    navigate(redirectUrl+"&"+currentParams)
                else
                    navigate(`${redirectUrl}?${currentParams.toString()}`);
            }
    }

    return(<></>)
}

