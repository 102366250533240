import { useEffect, useState } from "react"
import { SystemDataLoadingStatus, SystemUser } from "../../../Model/SystemModels";
import { SystemCore } from "../../../Core/System/SystemCore";
import { Loader } from "../Loader/Loader";
import { t } from "i18next";
import { Icon, PrimaryButton, Separator, Stack, mergeStyles, Text, Dialog, DialogType, DialogFooter, DefaultButton } from "@fluentui/react";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";

const EmailUnsubscribeContentContainer : React.FC<{}> = (props) => {

    const emailUnsubscribeContentContainer = mergeStyles([{
        width: '100%',
        margin: '0 auto',
        marginTop: '20px',
        padding: '20px'
    }]);

    return (
        <Stack key={"emailUnsubscribeContentContainer"} className={emailUnsubscribeContentContainer}>
            <Stack.Item>
                {props.children}
            </Stack.Item>
        </Stack>
    )
}

export interface IEmailUnsubscribeContainer {
    isLoading: SystemDataLoadingStatus;
    canProceed: boolean;
    isHidden: boolean;
    onNextClicked: () => void;
}

const EmailUnsubscribeContainer : React.FC<IEmailUnsubscribeContainer> = (props) => {

    const emailUnsubscribeContainer = mergeStyles([{
        width: '90%',
        margin: '0 auto',
        marginTop: '20px',
        border: '1px solid #f2f2f2',
        borderRadius: 10,
        boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)',
        display: 'flex',
        alignItems: 'center',
        padding: 20
    }]);

    const emailUnsubscribeContainerActions = mergeStyles([{
        width: '100%',
        paddingTop: 20,
    }]);

    const emailUnsubscribeActions = mergeStyles([{
        borderRadius: 8
    }]);
    
    return (
        <Stack key={"EmailUnsubscribeContainer"} className={emailUnsubscribeContainer}>
            <Stack.Item className={emailUnsubscribeContainerActions}>
                {props.children}
            </Stack.Item>
            <Stack hidden={props.isHidden} key={"EmailUnsubscribeContainerAction"} horizontal horizontalAlign="end" className={emailUnsubscribeContainerActions}>
                <Stack.Item>
                    <PrimaryButton
                        className={emailUnsubscribeActions}
                        styles={{flexContainer: {flexDirection: 'row-reverse'}, rootDisabled: {borderColor: '#838382'}, labelDisabled: {color: '#bebebe'}}}
                        text={t('common:proceed')!}
                        iconProps={{iconName: 'ChromeBackMirrored'}}
                        onClick={props.onNextClicked}
                        disabled={props.isLoading === SystemDataLoadingStatus.Loading || !props.canProceed} />
                </Stack.Item>
            </Stack>
        </Stack>
    );
}
    
const EmailUnsubscribeChecklistContainer : React.FC<{}> = (props) => {

    const emailUnsubscribeChecklistContainer = mergeStyles([{
        width: '100%',
        margin: '0 auto'
    }]);

    return (
        <Stack horizontal horizontalAlign="center" key="emailUnsubscribeChecklistContainer" className={emailUnsubscribeChecklistContainer}>
            {props.children}
        </Stack>
    );
}

const EmailUnsubscribePage = () => {
    
    const separatorStyles = {
        root: [{
            selectors: {
                '::before': {
                    background: '#bebebe',
                }
            }
        }]
    };
    
    const dialogContentProps = {
        type: DialogType.normal,
        title: t('common:EmailUnsubscribeCard:confirm')!,
        closeButtonAriaLabel: t('common:EmailUnsubscribeCard:close')!,
        subText: t('common:EmailUnsubscribeCard:confirmSubText')!,
    };

    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [userSystemId, setUserSystemId] = useState<string>("");
    const [user, setUser] = useState<SystemUser | undefined>(undefined);
    const [userEmail, setUserEmail] = useState<string>("");
    const [confirmMessage, setConfirmMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const adminService = new AdminService();

    useEffect(() => {
        if (dataStatus === SystemDataLoadingStatus.ToLoad)
        {
            getEmailParams();
        }
    }, [dataStatus])
    
    const getEmailParams = () => {
        let urlParams = new URLSearchParams(window.location.search);
        let userIdParam = urlParams.get('userId');
        if (userIdParam) {
            try {
                setUserSystemId(userIdParam);
                window.history.replaceState(null, '', window.location.pathname);
                checkEmailUnsubscribe(userIdParam);
            }
            catch {
                window.location.replace("/login");
            }
        }
        else
            window.location.replace("/login");
    }

    const translateErrorMessage = (message: string): string => {

        if (message === 'User not found')
            return t('common:EmailUnsubscribeCard:errors:userNotFound')!
        else if (message === 'The unsubscribe procedure has already been carried out for this user')
            return t('common:EmailUnsubscribeCard:errors:alreadyUnsubscribe')!

        return message;
    }

    const translateConfirmMessage = (message: string): string => {
        if (message === 'Unsubscription completed succesfully')
            return t('common:EmailUnsubscribeCard:confirmMessage')!

        return message;
    }
    
    const checkEmailUnsubscribe = (userId: string) => {
        adminService.checkUnsubscribeEmail(userId)
        .then((resp) => {
            setUser(resp);
            
            if (resp.privateEmail)
                setUserEmail(resp.privateEmail);
            else if (resp.userPrincipleName)
                setUserEmail(resp.userPrincipleName)
            else if (resp.contactEmail)
                setUserEmail(resp.contactEmail)

            setDataStatus(SystemDataLoadingStatus.Loaded);
        })
        .catch((err: string) => {
            setErrorMessage(translateErrorMessage(err));
            setDataStatus(SystemDataLoadingStatus.Loaded);
        })
    }

    const handleProceedClick = () => {
        setDataStatus(SystemDataLoadingStatus.Loading);

        adminService.unsubscribeEmail(user!)
        .then((resp: string) => {
            setConfirmMessage(translateConfirmMessage(resp));
        })
        .catch((err: string) => {
            setErrorMessage(translateErrorMessage(err));
        })
        .finally(() => {
            setShowDialog(false);
            setDataStatus(SystemDataLoadingStatus.Loaded);
        })
    }

    let iconStyle = mergeStyles([{
        fontSize: 30,
        color: '#2A7DE1'
    }]);

    let pStyle = mergeStyles([{
        marginBottom: 20
    }]);

    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={t("common:loadingData")!} />
    else
        return (
            <EmailUnsubscribeContainer canProceed={(user !== undefined)} isLoading={dataStatus} isHidden={(confirmMessage > '')} onNextClicked={() => {setShowDialog(true)}}>
                <EmailUnsubscribeChecklistContainer>
                    <Stack>
                        <Stack.Item>
                            <Text variant="xLarge" styles={{root: {color: '#2A7DE1' }}}>{t('common:EmailUnsubscribeCard:title')!}</Text>
                        </Stack.Item>
                    </Stack>
                </EmailUnsubscribeChecklistContainer>
                <Separator styles={separatorStyles} />
                <EmailUnsubscribeContentContainer>
                    <Stack>
                        {
                            (user && !confirmMessage)
                            ?
                            <>
                            <Stack.Item className={pStyle}>
                                <Stack horizontal>
                                    <Stack.Item>
                                        <Icon iconName="UserFollowed" className={iconStyle}></Icon>
                                    </Stack.Item>
                                    <Stack.Item styles={{ root: { lineHeight: 30, marginLeft: 10 }}}>
                                        <Text>{t('common:EmailUnsubscribeCard:hi')!} {(user.displayName) ? user.displayName! : user.firstName! + " " + user.lastName! }!</Text>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontal>
                                    <Stack.Item>
                                        <Icon iconName="Unsubscribe" className={iconStyle}></Icon>
                                    </Stack.Item>
                                    <Stack.Item styles={{ root: { lineHeight: 30, marginLeft: 10 }}}>
                                        <Text>{t('common:EmailUnsubscribeCard:description', {userEmail: userEmail})!}</Text>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            </>
                            :
                            <></>
                        }
                        {
                            confirmMessage
                            ?
                            <Stack horizontal>
                                <Stack.Item>
                                    <Icon iconName="SkypeCircleCheck" className={iconStyle} ></Icon>
                                </Stack.Item>
                                <Stack.Item styles={{ root: { lineHeight: 30, marginLeft: 10 }}}>
                                    <Text styles={{ root: { fontWeight: 500 }}}>{confirmMessage}</Text>
                                </Stack.Item>
                            </Stack>
                            :
                            <></>
                        }
                        {
                            errorMessage
                            ?
                            <Stack horizontal>
                                <Stack.Item>
                                    <Icon iconName="WarningSolid" className={iconStyle}></Icon>
                                </Stack.Item>
                                <Stack.Item styles={{ root: { lineHeight: 30, marginLeft: 10 }}}>
                                    <Text styles={{ root: { fontWeight: 500 }}}>{errorMessage}</Text>
                                </Stack.Item>
                            </Stack>
                            :
                            <></>
                        }
                    </Stack>
                </EmailUnsubscribeContentContainer>
                <Dialog
                    hidden={!showDialog}
                    onDismiss={() => {setShowDialog(false)}}
                    dialogContentProps={dialogContentProps}>
                    <DialogFooter>
                        <DefaultButton onClick={() => {setShowDialog(false)}} text={t('common:PageContainer:ConfirmDialog:cancelButtonText')!} />
                        <PrimaryButton onClick={handleProceedClick} text={t('common:PageContainer:ConfirmDialog:confirmButtonText')!} />
                    </DialogFooter>
                </Dialog>
            </EmailUnsubscribeContainer>
        )
}

export default EmailUnsubscribePage