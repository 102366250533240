import React, {useRef } from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { useNavigate } from "react-router-dom";
import { AdminEmailTemplateCard } from "../AdminEmailTemplateCard/AdminEmailTemplateCard";

export const AdminEmailTemplateList : React.FC = () => {
    const ref = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    const navigate = useNavigate();

    const initEmailTemplateLanguage = () => {
        ToastService.showPromiseMessage(
            adminService.initEmailTemplateLanguage(),
            "Inizializzazione in corso",
            "Completato con succeso",
            "Errore"
        );
    };

    return (
        <>
            <PageContainer endpoint="/odata/email/template" pageType={SystemPageType.List} title="Email Template" backAllowed insertAllowed deleteAllowed updateAllowed cardRef={ref}>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenTemplateLanguage" label="Template per localizzazione" iconName="LocaleLanguage" isPromoted runOnRec onClick={(req:any) => {
                            navigate("/app/admin/administrative/emailTemplateLanguage?templateCode="+req.emailTemplateCode);
                        }}>
                        </ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="initEmailTemplateLanguage" label="Inizializza email template language" iconName="Installation" onClick={(req:any)=>{
                            initEmailTemplateLanguage();
                         }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>               
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice template email" fieldName="emailTemplateCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice struttura" fieldName="structureCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipologia" fieldName="emailType" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                        if (item.emailType === 0)
                            return "Application"
                        else if (item.emailType === 1)
                            return "Advertising"
                    }} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminEmailTemplateCard title="Nuovo Template" ref={ref} />
        </>
    );
}