import React, {useRef, useState} from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { CommonUploadFileDialog } from "../../../Common/CommonUploadFileDialog/CommonUploadFileDialog";
import { AdminEmailTemplateStructureCard } from "../AdminEmailTemplateStructureCard/AdminEmailTemplateStructureCard";

export const AdminEmailTemplateStructureList : React.FC = () => {
    const ref = useRef<PageContainerBase>(null);
    const [structureCode, setStructureCode] = useState<string>("");
    const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
    const adminService = new AdminService();
    const currentPage = useRef<PageContainerBase>(null);

    const buildEndpoint = (): string => {
        let url = '/odata/email/template/structure';
        return url;
    }

    const uploadMailStructure = (file:File) => {
        setSaveInProgress(true);
        
        adminService.uploadMailStructure(structureCode, file)
        .then((resp)=>{
            setShowUploadDialog(false);
            setSaveInProgress(false)
            currentPage.current?.reloadData();
            ToastService.showMessage(MessageType.Success, "Upload completato.");
        })
        .catch((jqXHR)=>{
            setSaveInProgress(false)
            ToastService.showMessage(MessageType.Error, "Errore: Impossibile caricare il file.");
        })
    }

    const downloadMailStructure = (code: string, fileName:string) => {
        adminService.downloadMailStructure(code)
        .then((srvData)=>{
            Object.assign(document.createElement('a'), { href: 'data:text/html;charset=UTF-8,'+encodeURIComponent(srvData), download:fileName}).click();
            ToastService.showMessage(MessageType.Information, "Download completato con successo")
        })
        .catch((jqXHR)=>{
            ToastService.showMessage(MessageType.Error, "Errore durante il download del file: "+jqXHR.message);
        })
    };

    return (
        <>
            <PageContainer endpoint={buildEndpoint()} pageType={SystemPageType.List} title="Email Template Structure" backAllowed insertAllowed deleteAllowed updateAllowed cardRef={ref} ref={currentPage}>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="uploadFile" label="Carica Struttura" iconName="Upload" isPromoted runOnRec onClick={(req:any)=>{
                                setStructureCode(req.code);
                                setShowUploadDialog(true);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="downloadFile" label="Scarica Struttura" iconName="Download" isPromoted runOnRec onClick={(req:any)=>{
                                downloadMailStructure(req.code, req.fileName);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>               
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Nome file" fieldName="fileName" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Company information" fieldName="companyInformation" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Privacy policy url" fieldName="privacyPolicyUrl" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminEmailTemplateStructureCard ref={ref} />
            <CommonUploadFileDialog
                title="Carica Template"
                subText="Seleziona il file da caricare"
                hidden={showUploadDialog}
                txtLabel="File"
                ext=".html,.htm"
                saveInProgress={saveInProgress}
                onSuccess={uploadMailStructure}
                onDismiss={()=>{setShowUploadDialog(false)}}
            />
        </>
    );
}