export class UserCredential {
    public username: string = "";
    public password: string = "";
}

export class UserAuthenticationResult {
    public success: boolean = false;
    public error: string = "";
}

export class LoginRequestResponse {
    public success: boolean = false;
    public actionType: string = "";
    public userType: string = "";
    public message: string = "";
}