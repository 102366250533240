import { mergeStyles, Stack } from "@fluentui/react";
import { useContext, useEffect, useState } from "react"
import { Navigate, Outlet, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext"
import { SystemCore } from "../../../Core/System/SystemCore";
import { ITourEntry, SystemApp, SystemDataLoadingStatus, User, UserSessionInformation } from "../../../Model/SystemModels";
import { ApplicationNavBar } from "../ApplicationNavBar/ApplicationNavBar";
import { CommonNotificationPopup } from "../CommonNotificationPopup/CommonNotificationPopup";
import { ErrorDialog, IErrorDialogProps } from "../ErrorDialog/ErrorDialog";
import { Loader } from "../Loader/Loader";
import jwt from 'jwt-decode';
import i18next, { t } from "i18next";
import { RoleCenterHelpPanel } from "../../Demo/DemoRoleCenter/DemoRoleCenter";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { DemoUIHelper } from "../../Demo/DemoUIHelper";
import { BCNProcessTest } from "../../../Model/DemoModels";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";

export interface ApplicationOutletContext {
    isGuideOpen: boolean;
    searchKeyword: string;
    containerStyle:string;
    docsUrl: string;
    onOpenGuidePanel: (keyWord?:string) => void;
    onHandleContainerStyle: (isOpen: boolean) => void;
    trySetDocsUrl: (newValue: string) => void;
}

export function useOutlet() {
    return useOutletContext<ApplicationOutletContext>();
}

export const ApplicationContainer: React.FC = () => {
    const [dataStatusText, setDataStatusText] = useState("");
    const [dataStatus, SetDataStatus] = useState(SystemDataLoadingStatus.ToLoad);   
    const [systemInterval, setSystemInterval] = useState<number>(0);
    const [showGuidePanel, setShowGuidePanel] = useState<boolean>(false);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [lastSearch, setLastSearch] = useState<string>("");
    const [containerStyle,setContainerStyle] = useState<string>("");
    const [docsUrl,setDocsUrl] = useState<string>("");
    const [knowledgeBases, setKnowledgeBases] = useState<BCNProcessTest[]>([] as BCNProcessTest[]);
    const [guidePanelStatus, setGuidePanelStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.ToLoad);
    const [outletContext,setOutletContext] = useState<ApplicationOutletContext>({} as ApplicationOutletContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [errorDialogProps, SetErrorDialogProps] = useState({} as IErrorDialogProps);

    let sessionStateContext: ISessionStateContext = useContext(SessionStateContext);

    const getRequestedResource = () => {
        let requestedResource = HttpHelper.getParameter("resource");

        if ((requestedResource !== null) && (requestedResource !== ""))
            return atob(requestedResource);

        return undefined;
    }

    const createInterval = (claims: any) => {
        let d = new Date(0);
        let today = new Date();
        d.setUTCSeconds(claims.exp);
        let exprireTime = (d.getTime() - today.getTime());
        
        return window.setTimeout(() => {
            let systemCore: SystemCore = new SystemCore();
            systemCore.UserSessionExpired(sessionStateContext, navigate)
        }, exprireTime);
    }

    const buildRedirectUrl = (path: string) : string => {
        if (window.location.pathname != "/app") {
            const searchParams = new URLSearchParams(window.location.search);
            const emailParam = searchParams.get('email');
            if (emailParam)
                searchParams.delete('email');
            return path += "?resource=" + btoa(window.location.pathname+"?"+searchParams.toString())+(emailParam ? "&email="+emailParam : "");
        }
        return path;
    }

    const initPageStatus = () => {
        sessionStateContext.currentApp = new SystemApp();
        sessionStateContext.currentUser = new User();
        setDataStatusText("");
        SetDataStatus(SystemDataLoadingStatus.ToLoad);
    };

    const handleContainerStyle = (isOpen: boolean) =>{
        const ContainerClassName = mergeStyles([{
            //maxHeight: 'calc(100vh - 51px)',
            backgroundColor: 'white !important',
            paddingTop: 51,
            width: '100%',
            zIndex: 10,
        }]);

        const MinifiedContainerClassName = mergeStyles([{
            width: "78%",
            //maxHeight: 'calc(100vh - 51px)',
            backgroundColor: 'white !important',
            paddingTop: 51,
            transition: 'width 0.01s linear',
            zIndex: 10,
        }]);

        if(isOpen){
            setGuidePanelStatus(SystemDataLoadingStatus.ToLoad);
            setKnowledgeBases([]);
            setContainerStyle(MinifiedContainerClassName);
            setShowGuidePanel(true);
        }
        else{
            setGuidePanelStatus(SystemDataLoadingStatus.ToLoad);
            setKnowledgeBases([]);
            setContainerStyle(ContainerClassName);
            setShowGuidePanel(false);
        }
    }

    const handleSearchKeyword = (newValue: string, forceSearch: Boolean = false) => {
        if (newValue) {
            if (newValue !== lastSearch || forceSearch) {
                setGuidePanelStatus(SystemDataLoadingStatus.Loading);
                setKnowledgeBases([]);
                setLastSearch(newValue);
                SystemCore.searchKB(HttpHelper.buildAxiosInstance(), newValue, true).then((resp) => {
                    setGuidePanelStatus(SystemDataLoadingStatus.Loaded);
                    setKnowledgeBases(resp);
                })
                .catch((err)=>{
                    console.log(err.message);                
                    setGuidePanelStatus(SystemDataLoadingStatus.Loaded);
                    setKnowledgeBases([]);
                    ToastService.showMessage(MessageType.Error, t('common:GuidePanel:errorMessage')!);
                });
            }
        }
        else
            ToastService.showMessage(MessageType.Warning, t('common:GuidePanel:emptyTextBox')!);
    }
    
    const handleGuideOpen = (keyWord?:string) => {
        setSearchKeyword("");
        setLastSearch("");
        
        if((keyWord !== null) && (keyWord !== undefined)){
            handleContainerStyle(true);
            
            if(keyWord !== ""){
                setSearchKeyword(keyWord!);
                handleSearchKeyword(keyWord!);

                if(sessionStateContext.currentApp.homepageUrl.includes('demo'))
                    CommonFunctions.logEvent("Ricerca guida: "+keyWord!,"");
            }
        }
    }

    const setDocumentationPortalUrl = (newValue: string) => {
        setDocsUrl(newValue);
    }

    useEffect(() => {
        if (dataStatus == SystemDataLoadingStatus.ToLoad)
        {
            let systemCore: SystemCore = new SystemCore();
            SetDataStatus(SystemDataLoadingStatus.Loading);
            setDataStatusText(t('common:ApplicationContainer:dataStatusText1')!);

            handleContainerStyle(false);

            setOutletContext(
                {
                    isGuideOpen: showGuidePanel,
                    searchKeyword: searchKeyword,
                    containerStyle:containerStyle,
                    docsUrl: docsUrl,
                    onOpenGuidePanel: handleGuideOpen,
                    onHandleContainerStyle: handleContainerStyle,
                    trySetDocsUrl: setDocumentationPortalUrl
                }
            );

            systemCore.getUserSessionInformation().then((sessionInfo) => {
                sessionStateContext.currentApp = sessionInfo.app;
                sessionStateContext.currentUser = sessionInfo.user;
                sessionStateContext.currentUserSettings = sessionInfo.userSettings;
                document.title = sessionStateContext.currentApp.title;
                SystemCore.setFavicons("data:image/png;base64,"+sessionStateContext.currentApp.appIcon);
                setDataStatusText(t('common:ApplicationContainer:dataStatusText2')!);

                systemCore.getUserFeatures().then((features) => {
                    sessionStateContext.entries = features;
            
                    if(sessionInfo.userSettings.userLanguageCode !== systemCore.formatLanguage(i18next.language))
                        systemCore.setUserLanguageCode(sessionInfo.userSettings).then(()=>{
                            sessionStateContext.currentUserSettings.userLanguageCode = systemCore.formatLanguage(i18next.language);
                        });

                    SetDataStatus(SystemDataLoadingStatus.Loaded);

                    let token = systemCore.getUserToken();
                    let claims = jwt(token);
                    setSystemInterval(createInterval(claims));

                    let redirectUrl = getRequestedResource();
                    if (redirectUrl === undefined)
                    {
                        if (window.location.pathname == "/app")
                            navigate(sessionStateContext.currentApp.homepageUrl);
                    }
                    else
                        navigate(redirectUrl);
                })
                .catch((err) => {

                });
            })
            .catch((resp) => {
                SetDataStatus(SystemDataLoadingStatus.Error);
                SetErrorDialogProps(SystemCore.createErrorDialogProps(t('common:ApplicationContainer:errorDialogText')!, resp.message, initPageStatus));
            });
        }
    }, [dataStatus]);

    useEffect(() => {
        if (dataStatus == SystemDataLoadingStatus.Loaded)
        {
            if (location.pathname == "/app")
            {
                document.title = sessionStateContext.currentApp.title;
                navigate(sessionStateContext.currentApp.homepageUrl);
            }
        }
    }, [location.pathname]);

    if (sessionStateContext.isAuthenticated)
    {
        if (! SystemCore.isFinishedDataStatus(dataStatus))
            return <Loader text={dataStatusText} />
        else
        {
            if (dataStatus == SystemDataLoadingStatus.Error)
            {
                return <ErrorDialog showDialog={errorDialogProps.showDialog}
                                    text={errorDialogProps.text}
                                    title={errorDialogProps.title}
                                    onUpdateClick={errorDialogProps.onUpdateClick}/>;
            }
            else
            {
                return (
                    <>
                        <ApplicationNavBar
                            docsUrl={docsUrl}
                            onOpenGuidePanel={()=>{handleGuideOpen("");}}
                            onHandleContainerStyle={(isOpen: boolean)=>{handleContainerStyle(isOpen)}}
                        />

                        <Stack className={containerStyle}>
                            <Outlet context={outletContext}></Outlet>
                        </Stack>
                        <RoleCenterHelpPanel
                            isOpen={showGuidePanel}
                            value={searchKeyword}
                            onChange={(_,newValue)=>{setSearchKeyword(newValue!)}}
                            onClear={() => {
                                setSearchKeyword("");
                                setLastSearch("");
                                setKnowledgeBases([]);
                                setGuidePanelStatus(SystemDataLoadingStatus.ToLoad);
                            }}
                            onBlur={() => {
                                if (searchKeyword)
                                    handleSearchKeyword(searchKeyword)
                            }}
                            onDismiss={()=>handleContainerStyle(false)}
                            onSearch={(newValue)=>handleSearchKeyword(newValue!, true)}
                            kbList={knowledgeBases}
                            dataStatus={guidePanelStatus}
                        />
                    </>
                );
            }
        }
    }
    else
        return <Navigate to={buildRedirectUrl("/login")} />;

}