import { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminCRMLegalEntityCard } from "../AdminCRMLegalEntityCard/AdminCRMLegalEntityCard";

export const AdminCRMLegalEntityList : React.FC = () => {

    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint="/odata/crm/legal/entity" pageType={SystemPageType.List} title="Lista CRM Legal Entity" backAllowed insertAllowed updateAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={250} maxWidth={250} />
                    <ListHeaderEntry name="Id CRM" fieldName="crmReferenceId" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Cod. Distributore default" fieldName="defaultDistributorCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Partner default" fieldName="defaultPartnerId" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Customer App Id default" fieldName="defaultCustomerAppId" minWidth={100} maxWidth={250} />
                    <ListHeaderEntry name="Public Register Url" fieldName="publicRegisterUrl" minWidth={100} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminCRMLegalEntityCard ref={cardRef} />
        </>
    );
}