import React, { useContext, useEffect, useRef, useState } from "react";
import { Customer, CustomerDashboardCue, CustomerEnvironment } from "../../../../Model/CustomerModels";
import { SystemActionCategory, SystemDataLoadingStatus,OperationType, SystemEntity, SystemPage, SystemPageType, SystemOperation } from "../../../../Model/SystemModels";

import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import {Link, Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { ILocation } from "../../../../Model/AdminModel";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { IColumn } from "@fluentui/react";
import { AdminDsyDataRequestCard } from "../AdminDsyDataRequestCard/AdminDsyDataRequestCard";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { CommonJsonToTableDialog } from "../../../Common/CommonJsonToTableDialog/CommonJsonToTableDialog";
import { Loader } from "../../../Common/Loader/Loader";
import { SystemCore } from "../../../../Core/System/SystemCore";


export const AdminDsyDataRequestList : React.FC = () => {
    const [customerId,setCustomerId] = useState(HttpHelper.getParameter("customerId"));
    const [envName,setEnvName] = useState(HttpHelper.getParameter("envName"));
    const [dataStatus,setDataStatus] = useState<SystemDataLoadingStatus>(SystemDataLoadingStatus.Loaded);
    const [jsonData,setJsonData] = useState([]);
    const [showTableDialog,setShowTableDialog] = useState<boolean>(false);
    const adminService = new AdminService();
    const cardRef = useRef<PageContainerBase>(null);

    const buildListEndpoint = () : string =>{
        let endpoint = ""

        if((customerId !== null) && (envName !== null))
            endpoint = `/api/BusinessCentral/${customerId}/dsy/data/request/${envName}`;
        else
            ToastService.showMessage(MessageType.Error,"Errore nella creazione dell'endpoint"); 

        return endpoint;
    }

    const onSuccessCard = (operationType: SystemOperation, record: any, callback?: (() => void)) => {
        // adminService.manageDsyDataRequest(record,customerId,envName,operationType).then(()=>{
        //     if (callback !== undefined)
        //         callback!();
            
        //     cardRef.current?.closePage();
        // })
        // .catch((jqXHR)=>{
        //     ToastService.showMessage(MessageType.Error, jqXHR.message);
        //     cardRef?.current?.handleError();
        // })
    }

    const onDeleteRecord = (record:any) => {
        cardRef.current?.setRecord(record);
        cardRef.current?.openPage(SystemOperation.Delete);
    }

    const sendDsyDataRequest = (req:any) => {
        // setDataStatus(SystemDataLoadingStatus.Loading);
        // adminService.executeDsyDataRequestOperation(req,customerId,envName).then((resp:any)=>{
        //     setJsonData(resp);
        //     setDataStatus(SystemDataLoadingStatus.Loaded);
        //     setShowTableDialog(true);
        // })
        // .catch((jqXHR)=>{
        //     setJsonData([]);
        //     ToastService.showMessage(MessageType.Error, jqXHR.message);
        //     setDataStatus(SystemDataLoadingStatus.Loaded);
        // })
    }

    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text="La tua richiesta è in corso..."></Loader>;

    return (
        <>
            <PageContainer 
                endpoint={buildListEndpoint()} 
                pageType={SystemPageType.List} 
                title={"Richieste dati DSY"} 
                backAllowed 
                insertAllowed 
                updateAllowed 
                deleteAllowed
                cardRef={cardRef}
                onDeleteRecord={onDeleteRecord}
                onSuccess={onSuccessCard}
            >               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry iconName="SearchData" label="Richiedi Dati" name="DsyDataRequest" runOnRec onClick={(req:any)=>{
                                sendDsyDataRequest(req)
                            }}
                        >
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Entry No." fieldName="entryNo" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Nr. Tabella" fieldName="tableNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Nr. Campo" fieldName="fieldNo" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Filtri Tabella" fieldName="tableFilters" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Tipo Operazione" fieldName="operationType" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminDsyDataRequestCard onSuccess={onSuccessCard} ref={cardRef} />
            <CommonJsonToTableDialog
                showDialog={showTableDialog}
                title={"Dati richiesta DSY"}
                subText={"Dati della richiesta DSY"}
                json={jsonData}
                onDismiss={() => setShowTableDialog(false)}
                onSuccess={() => setShowTableDialog(false)}
            />
        </>
    );
}