import { BaseButton, Button, Icon, Image, ImageFit, Link, mergeStyles, PrimaryButton, Separator, Stack, TextField, Text, ITextProps, ITextFieldProps } from '@fluentui/react';
import * as React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import LogoSoSmart from '../../../Assets/Images/logoSoSmart.png';
import { Loader } from '../Loader/Loader';
import { UserCredential } from '../../../Model/SecurityModel';
import { SystemCore } from '../../../Core/System/SystemCore';
import { ISessionStateContext, SessionStateContext } from '../../../Core/State/SessionStateContext';
import { MessageType, ToastService } from '../../../Core/Toast/ToastService';
import { LoginForgotPasswordModal } from '../LoginForgotPasswordModal/LoginForgotPasswordModal';
import { CommonFunctions } from '../../../ApplicationCode/Common/CommonFunctions';
import { HttpHelper } from '../../../Core/Http/HttpHelper';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { SystemDataLoadingStatus } from '../../../Model/SystemModels';

// initializeIcons();
                
const loginMsButtonClass = mergeStyles([{
    width: '90%',
    height: 50,
    fontSize: 18,
    borderRadius: 5
}]);

const loginButtonClass = mergeStyles([{
    width: '100%',
    marginTop: 25
}]);

const loginBoxContainer = mergeStyles([{
    width: '22%',
    maxHeight: '850px',
    minWidth: '425px',
    backgroundColor: 'white',
    border: '0 solid rgba(0,0,0,.125)',
    boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)',
    borderRadius: 8,
    borderTop: '5px solid #007bff'
}]);

const loginBoxPageContainer = mergeStyles([{
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#e9ecef !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}]);

const loginLogoContainer = mergeStyles([{
    width: '100%',
    borderBottom: '2px solid rgba(0,0,0,.125)'
}]);

const windowsLoginContainer = mergeStyles([{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 30,
    paddingBottom: 30
}]);

const registerLinkClassName = mergeStyles([{
    width: '100%',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #d4d4d4'
}]);

const separatorLoginContainer = mergeStyles([{
    width: '100%',
    paddingTop: 5,
    paddingBottom: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 13
}]);

const separatorStyles = {
    root: [{
        selectors: {
            '::before': {
                background: 'rgba(42, 125, 225, 0.3)',
            },
        }
    }]
};

export interface IWindowsLoginForm {
    redirectUrl: string;
}

const WindowsLoginForm: React.FC<IWindowsLoginForm> = (props) => {
    return (
        <Stack tokens={{childrenGap: 10}}>
            <Stack.Item className={windowsLoginContainer}>
                <PrimaryButton
                    toggle
                    text={t('common:LoginBox:primaryButtonText')!}
                    iconProps={{
                        iconName: 'WindowsLogo',
                        style: {
                            fontSize: 25
                        }
                    }}
                    allowDisabledFocus
                    className={loginMsButtonClass}
                    onClick={() => {
                        window.location.replace(props.redirectUrl);
                    }}/>
            </Stack.Item>
        </Stack>
    );
}

export interface IHelpLoginForm {
    username: string;
    password: string;
    showHelpLogin: boolean;
    errorMessage?: string;
    saveInProgress: boolean;
    showPrivateLoginBox: boolean;
    confirmButtonDisabled: boolean;
    handleUsernameChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void;
    handlePasswordChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void;
    handleLoginClick: (ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton | Button, MouseEvent>) => void;
    handleCheckUserType: () => void;
    setShowHelpLogin: React.Dispatch<React.SetStateAction<boolean>>
    setShowEmailDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const HelpLoginForm: React.FC<IHelpLoginForm> = (props) => {    
    let privateFormContainer = mergeStyles([{
        padding: 20,
        paddingTop: 0,
        display: (props.showHelpLogin) ? 'block' : 'none',
    }]);

    let privateLoginBox = mergeStyles([{
        display: (props.showPrivateLoginBox) ? 'block' : 'none',
    }]);
    
    let confirmButtonContainer = mergeStyles([{
        display: (props.confirmButtonDisabled) ? 'none' : 'block',
    }]);
    
    const onRenderDescription = (props?: ITextFieldProps | undefined, defaultRender?: ((props?: ITextFieldProps | undefined) => JSX.Element | null) | undefined): JSX.Element | null => {
        return (
            <Text variant="xSmall" style={{fontWeight: "bold"}}>
                {props!.description}
            </Text>
        );
    };
    
    return (
        <>
            <Stack.Item>
                <Separator styles={separatorStyles}>{t('common:LoginBox:separatorText')!}</Separator>
            </Stack.Item>
            <Stack.Item className={separatorLoginContainer}>
                <Stack horizontal tokens={{childrenGap: 10}}>
                    <Stack.Item>
                        <Text key={"privateTitle"} variant={"small" as ITextProps["variant"]}>
                            {t('common:LoginBox:helpText')!}
                        </Text>
                    </Stack.Item>
                    <Stack horizontal tokens={{childrenGap: 5}}>
                        <Stack.Item>
                            <Text key={"clickHere"} variant={"small" as ITextProps["variant"]}>
                                <Link underline onClick={() => props.setShowHelpLogin(!props.showHelpLogin)}>{t('common:LoginBox:forgotPasswordLinkText')!}</Link>
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Icon style={{color: "#2A7DE1", cursor: "pointer", lineHeight: "25px"}} iconName={(props.showHelpLogin) ? "ChevronUpSmall" : "ChevronDownSmall"} onClick={() => props.setShowHelpLogin(!props.showHelpLogin)} />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack.Item>
            <Stack.Item className={privateFormContainer}>
                <TextField label={t('common:LoginBox:emailText')!} value={props.username} onChange={props.handleUsernameChange} errorMessage={props.errorMessage} />
                <Stack.Item className={privateLoginBox}>
                    <TextField
                        type='password'
                        label={t('common:LoginBox:passwordText')!}
                        description={t('common:LoginBox:passwordText2')!}
                        value={props.password}
                        onChange={props.handlePasswordChange}
                        onRenderDescription={onRenderDescription}
                        canRevealPassword />
                    <PrimaryButton toggle text={t('common:LoginBox:primaryButtonText2')!} allowDisabledFocus className={loginButtonClass} onClick={props.handleLoginClick} />
                    <Stack horizontal style={{
                        marginTop: 10,
                        fontSize: 12
                    }}>
                        {t('common:LoginBox:forgotPasswordText')!}&nbsp;<Link onClick={() => props.setShowEmailDialog(true)} underline>{t('common:LoginBox:forgotPasswordLinkText')!}</Link>
                    </Stack>
                </Stack.Item>
                <Stack.Item className={confirmButtonContainer}>
                    <PrimaryButton disabled={props.saveInProgress || props.confirmButtonDisabled} toggle text={t('common:LoginBox:primaryButtonText3')!} allowDisabledFocus className={loginButtonClass} onClick={props.handleCheckUserType} />
                </Stack.Item>
            </Stack.Item>
        </>
    );
}

const RegisterLink: React.FC = () => {
    return (
        <Stack.Item>
            <Stack horizontal key="registerLink" className={registerLinkClassName}>
                <Stack.Item>
                    <Text key={"accountNeeded"} variant={"small" as ITextProps["variant"]}>
                        {t("common:LoginBox:accountNeeded")!}
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Text key={"login"} variant={"small" as ITextProps["variant"]}>
                        <Link style={{ position: "relative" }} href="register" underline>
                            {t("common:LoginBox:register")!}
                            <Icon style={{ position: "absolute", left: 60, top: 3 }} iconName="DoubleChevronRight" />
                        </Link>
                    </Text>
                </Stack.Item>
            </Stack>
        </Stack.Item>
    );
};

export const ForceLoginBox : React.FC = () => {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [showLoader, setShowLoader] = React.useState(false);
    const [loaderMessage, setLoaderMessage] = React.useState('Caricamento in corso...');
    const [showEmailDialog, setShowEmailDialog] = React.useState(false);
    const [saveInProgress, setSaveInProgress] = React.useState(false);
    const [showHelpLogin, setShowHelpLogin] = useState(false);
    const [showPrivateLoginBox, setShowPrivateLoginBox] = useState(false);
    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [logo, setLogo] = useState<string>();
    const sessionStateContext: ISessionStateContext = React.useContext(SessionStateContext);
    const navigate = useNavigate();
    
    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.ToLoad)
        {
            setLoaderMessage('Caricamento in corso...')
            setShowLoader(true);
            setDataStatus(SystemDataLoadingStatus.Loading);
            
            let systemCore = new SystemCore();
            
            if(systemCore.getUserToken() === "" && !sessionStateContext.isAuthenticated)
            {
                systemCore.authenticateUserFromRefreshToken().then(()=>{
                    sessionStateContext.isAuthenticated = true;
                    navigate("/app");
                })
            }

            SystemCore.getSystemAppPublicInfo(window.location.origin).then((resp:any)=>{
                document.title = resp.appTitle;
                SystemCore.setFavicons("data:image/png;base64,"+resp.appIcon);
                setLogo("data:image/png;base64,"+resp.appLogo);
                setShowLoader(false);
            
                setDataStatus(SystemDataLoadingStatus.Loaded);
            })
            .catch((jqXHR)=>{
                setShowLoader(false);
                setLogo(LogoSoSmart);
            
                setDataStatus(SystemDataLoadingStatus.Loaded);
            });
        }
    },[dataStatus])
    

    const checkAuthenticationParameter = () => {
        var url = new URL(window.location.toString());
        var ctx = url.searchParams.get("ctx");
        
        if (! showLoader)
        {
            if (ctx != null)
            {
                ctx = atob(ctx);
                setShowLoader(true);
                
                let systemCore: SystemCore = new SystemCore();
                systemCore.authenticateUserFromParameter(ctx)
                    .then((resp) => {
                        sessionStateContext.isAuthenticated = true;
                        navigate("/app");
                    })
                    .catch((err) => {
                        ctx = null;
                        setShowLoader(false);
                        ToastService.showMessage(MessageType.Error, err);
                        navigate("/login");
                    });
            }
        }
    };

    useEffect(() => {
        checkAuthenticationParameter();
    }, []);
    
    const getRequestedResource = () => {
        let requestedResource = HttpHelper.getParameter("resource");
        if ((requestedResource !== undefined) && (requestedResource !== ""))
            return atob(requestedResource);
        
        return undefined;
    }

    let redirectUrl = "https://login.microsoftonline.com/68ddd42b-912d-4bd9-9c17-7c20ab9aaa7d/oauth2/authorize?client_id=d459d8e8-09d5-4ac1-9075-f31eb980c70c&response_type=id_token&redirect_uri=" + window.location.origin + "/ms/afterauth&response_mode=fragment&scope=https://graph.microsoft.com/.default&nonce=678910";
    let callbackUrl = getRequestedResource();
    if (callbackUrl)
        redirectUrl += "&state=" + btoa(callbackUrl);

    const handleUsernameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        setUsername(newValue!);
    }

    const handlePasswordChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        setPassword(newValue!);
    }

    const handleEmailDialogSave = (email: string) => {
        setSaveInProgress(true);
        
        CommonFunctions.handlePasswordReset(email)
            .then(() => {
                ToastService.showMessage(MessageType.Success, "Ti abbiamo inviato l'email con il link all'email indicata");
                setShowEmailDialog(false);
                setSaveInProgress(false);
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, err.message);
                setSaveInProgress(false);
            })
    }

    const handleLoginClick = (ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton | Button, MouseEvent>) => {
        setLoaderMessage('Autenticazione in corso...');
        setShowLoader(true);

        let systemCore: SystemCore = new SystemCore();
        let credential: UserCredential = {
            username: username,
            password: password
        };

        ev.preventDefault();
        systemCore.authenticateUser(credential).then((resp) =>{
            sessionStateContext.isAuthenticated = true;
            let requestedResource = getRequestedResource();
            if (requestedResource)
                navigate(requestedResource);
            else
                navigate("/app");
            return true;
        })
        .catch((resp) => {
            ToastService.showMessage(MessageType.Error, resp.error);

            setUsername("");
            setPassword("");
            setShowLoader(false);
            resetState();

            return false;
        });
    }

    const handleCheckUserType = () => {
        setSaveInProgress(true);
        
        CommonFunctions.getUserType(username)
            .then((resp) => {
                if (resp.userType === "AAD") {
                    window.location.replace(redirectUrl);
                    setSaveInProgress(false);
                }
                else if (resp.userType === "Private") {
                    setConfirmButtonDisabled(true);
                    setShowPrivateLoginBox(true);
                    setSaveInProgress(false);
                }
                else if (resp.userType === "") {
                    setErrorMessage(t("common:LoginBox:registerText")!);
                    setSaveInProgress(false);
                }
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, err.message);
                setSaveInProgress(false);
            })
    }

    const resetState = () => {
        setErrorMessage("");
        setConfirmButtonDisabled(false);
        setShowPrivateLoginBox(false);
    }
    
    if (! sessionStateContext.isAuthenticated)
    {
        if (! showLoader)
        {
            return (
                <Stack className={loginBoxPageContainer}>
                    <Stack className={loginBoxContainer}>
                        <Stack.Item className={loginLogoContainer}>
                            <Image src={logo} imageFit={ImageFit.cover} style={{
                                padding: 5,
                                marginTop: 4,
                                marginBottom: 8
                            }} />
                        </Stack.Item>
                        <WindowsLoginForm
                            redirectUrl={redirectUrl} />
                        <HelpLoginForm
                            username={username}
                            password={password}
                            showHelpLogin={showHelpLogin}
                            errorMessage={errorMessage}
                            saveInProgress={saveInProgress}
                            showPrivateLoginBox={showPrivateLoginBox}
                            confirmButtonDisabled={confirmButtonDisabled}
                            handleUsernameChange={handleUsernameChange}
                            handlePasswordChange={handlePasswordChange}
                            handleCheckUserType={handleCheckUserType}
                            setShowHelpLogin={setShowHelpLogin}
                            handleLoginClick={handleLoginClick}
                            setShowEmailDialog={setShowEmailDialog} />
                        <RegisterLink />
                    </Stack>
                    <LoginForgotPasswordModal
                        onCancel={() => {setShowEmailDialog(false);}}
                        onSave={handleEmailDialogSave}
                        showModal={showEmailDialog}
                        saveInProgress={saveInProgress} />
                </Stack>
            );
        }
        else
            return <Loader text={loaderMessage}></Loader>;
    }
    else
        return <Navigate to="/app"></Navigate>;
} 