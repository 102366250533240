import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { CustomerInformationContextProvider } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { Customer, CustomerDomain, CustomerSetupConfig, CustomerVersion } from "../../../Model/CustomerModels";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader"
import { t } from "i18next";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { ApplicationOutletContext, useOutlet } from "../../Common/ApplicationContainer/ApplicationContainer";

export const CustomerApplicationContainer : React.FC = () => {
    const [dataStatusText, setDataStatusText] = useState("");
    const [setupConfig, setSetupConfig] = useState(new CustomerSetupConfig());
    const [defaultDomain, setDefaultDomain] = useState(new CustomerDomain());
    const [customer, setCustomer] = useState<Customer>(new Customer());
    const [dataStatus, SetDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    let sessionStateContext: ISessionStateContext = useContext(SessionStateContext);
    const outletContext:ApplicationOutletContext = useOutlet();
    let customerService = new CustomerService();
    let systemCore = new SystemCore();

    const location = useLocation();
    
    useEffect(() => {
        if (dataStatus == SystemDataLoadingStatus.ToLoad)
        {
            SetDataStatus(SystemDataLoadingStatus.Loading);
            setDataStatusText(t('customerCenter:CustomerApplicationContainer:dataStatusText1')!);
            
            customerService.getCustomer(sessionStateContext.currentUser.referenceId).then((custResp) => {
                setCustomer(custResp);
                customerService.getCustomerSetupConfiguration(sessionStateContext).then((resp) => {
                    let currentConfig = resp;
                    setSetupConfig(currentConfig);
                    outletContext.trySetDocsUrl(buildDocumentationPortalUrl(custResp))
                    
                    try {
                        systemCore.manageUserLicense(sessionStateContext.currentUser.userId);
                    }
                    catch{}

                    customerService.loadCustomerDefaultDomain(resp.customerId).then((resp) => {
                        setDefaultDomain(resp);
                        SetDataStatus(SystemDataLoadingStatus.Loaded);
                    })
                    .catch((err) => {
                        SetDataStatus(SystemDataLoadingStatus.Loaded);
                    });
                })
                .catch((resp) => {
                    SetDataStatus(SystemDataLoadingStatus.Loaded);
                });
            })
            .catch(() => {
                SetDataStatus(SystemDataLoadingStatus.Loaded);
            });
        }
    }, [dataStatus])

    useEffect(() => {
        CommonFunctions.logSystemEvent("Customer navigation", "User navigate on page "+location.pathname, 0, sessionStateContext.currentUser.userId, "ACCESS", "NAVIGATE", location.pathname);
    }, [location.pathname])

    const buildDocumentationPortalUrl = (customer: Customer): string => {
        let localization: string = "";
        if(customer.customerVersion == CustomerVersion.V3){
            if (customer.legalEntityCode == 'EOL'){
                localization = 'it-IT';
            }
            else{
                localization = 'en-US'
            }
            return('/app/customer/docs/' + localization);
        }
        else {
            return('/docs');
        }
    }
    
    
    if (! SystemCore.isFinishedDataStatus(dataStatus))
    {
        return (
            <Loader text={dataStatusText}></Loader>
        );
    }
    else
    {
        if (dataStatus == SystemDataLoadingStatus.Error)
            return <>Error</>;
            
        return (
            <CustomerInformationContextProvider
                setupConfig={setupConfig}
                defaultDomain={defaultDomain}
                customer={customer}
            >
                <Outlet></Outlet>
            </CustomerInformationContextProvider>
        );
    }
}
