import React, { useRef } from "react";
import { SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { AdminUserSettingsCard } from "../AdminUserSettingsCard/AdminUserSettingsCard";

export const AdminUserSettingsList : React.FC = () => {
    
    const cardRef = useRef<PageContainerBase>(null);

    const userId: Number = HttpHelper.getParameter("userId");

    const buildListEndpoint = () : string =>{
        
        let endpoint = "/odata/user/settings";
        
        if (userId) {
            endpoint += "?$filter=userId eq "+ userId;
        }
        else {
            endpoint += "?$filter=userId eq 0";
        }
        
        return endpoint;
    }
    
    return (
        <>
            <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={"Impostazioni utente"} cardRef={cardRef} backAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}></PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id utente" fieldName="userId" minWidth={300} maxWidth={250} />
                    <ListHeaderEntry name="Nascondi tour guidato" fieldName="hideCustomerCommonGuideTour" minWidth={400} maxWidth={250} />
                    <ListHeaderEntry name="Codice lingua utente" fieldName="userLanguageCode" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name="URL calendario booking" fieldName="bookingCalendarUrl" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name="Job Title" fieldName="jobTitle" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name="URL Picture" fieldName="pictureUrl" minWidth={200} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminUserSettingsCard ref={cardRef} />
        </>
    );
}