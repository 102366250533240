import { DefaultButton, Dialog, DialogFooter, IModalProps, IStyle, PrimaryButton, Stack } from "@fluentui/react";
import { Guid } from "guid-typescript";
import { t } from "i18next";
import { CSSProperties, useEffect, useState } from "react";
import { SystemDataLoadingStatus, Ticket } from "../../../Model/SystemModels";
import { CommonCreateTicketForm } from "../../Common/CommonCreateTicketForm/CommonCreateTicketForm";
import { RoleCenterHelpPanel } from "../../Demo/DemoRoleCenter/DemoRoleCenter";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { BCNProcessTest } from "../../../Model/DemoModels";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { SystemCore } from "../../../Core/System/SystemCore";

export interface ICustomCustomerIncidentCardProps {
    showCard: boolean;
    saveInProgress: boolean;
    record: Ticket;
    customStyle?: CSSProperties;
    onCancel: () => void;
    onSave: (record: Ticket) => void;
}

export class CustomAttachment {
    id: string = Guid.create().toString();
    fileExtension: string = "";
    fileName: string = "";
    fileBlob: string = "";
}

export const CustomCustomerIncidentCard: React.FC<ICustomCustomerIncidentCardProps> = (props) =>  {
    
    const shrinkedModalIStyle: IStyle = {
        width: '79% !important',
        maxWidth: 'calc(79%) !important',
        position: 'absolute',
        top: 52,
        left: 0
    }
    
    const fullModalIStyle: IStyle = {
        width: '95% !important',
        maxWidth: 'calc(95%) !important',
        position: 'absolute',
        top: 50
    }

    const [record, setRecord] = useState(new Ticket());
    const [knowledgeBases, setKnowledgeBases] = useState<BCNProcessTest[]>([] as BCNProcessTest[]);
    const [helpPanelStatus, sethelpPanelStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [lastSearch, setLastSearch] = useState<string>("");
    const [modalStyle, setModalStyle] = useState<IStyle>(shrinkedModalIStyle);
    const [showHelpPanel, setShowHelpPanel] = useState<boolean>(true);
    const [timeLeft, setTimeLeft] = useState(3);
    
    const pageCardModalProps: Partial<IModalProps> = {
        isBlocking: true,
        dragOptions: undefined,
        styles: {
            main: modalStyle
        },
        layerProps:{styles:{root:{zIndex: props.customStyle?.zIndex ? props.customStyle.zIndex : '50000 !important'}}},
    };

    useEffect(()=>{
        if(props.showCard){
            setRecord(props.record);
            setModalStyle(shrinkedModalIStyle);
            setShowHelpPanel(true);
        }
        else
        {
            setShowHelpPanel(false);
            sethelpPanelStatus(SystemDataLoadingStatus.ToLoad);
            setSearchKeyword("");
            setLastSearch("");
            setKnowledgeBases([]);
        }
    },[props.showCard])

    useEffect(()=>{
        setSearchKeyword(record.ticketInfo.title);
    }, [record.ticketInfo.title]);
    
    useEffect(() => {
        setTimeLeft(3);
    }, [searchKeyword]);
    
    useEffect(() => {
        if (timeLeft === 0) {
            if (searchKeyword)
                handleSearchKeyword(searchKeyword);
                
            return;
        }
        
        const intervalId = setTimeout(() => {setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);}, 1000);
        
        return () => clearTimeout(intervalId);
    }, [timeLeft]);
    
    const handleSearchKeyword = (newValue: string, forceSearch: Boolean = false) => {
        if (newValue) {
            if (newValue !== lastSearch || forceSearch) {
                sethelpPanelStatus(SystemDataLoadingStatus.Loading);
                setKnowledgeBases([]);
                setLastSearch(newValue);
                SystemCore.searchKB(HttpHelper.buildAxiosInstance(), newValue, true)
                .then((resp) => {
                    sethelpPanelStatus(SystemDataLoadingStatus.Loaded);
                    setKnowledgeBases(resp);
                })
                .catch((err) => {
                    console.log(err.message);
                    sethelpPanelStatus(SystemDataLoadingStatus.Loaded);
                    setKnowledgeBases([]);
                    ToastService.showMessage(MessageType.Error, t('common:GuidePanel:errorMessage')!);
                });
            }
        }
        else
            ToastService.showMessage(MessageType.Warning, t('common:GuidePanel:emptyTextBox')!);
    }
    
    return (
        <Dialog 
            hidden={!props.showCard}
            modalProps={pageCardModalProps}
            onDismiss={props.onCancel}
            dialogContentProps={{
                title: "Ticket - " + record.ticketInfo.title
            }}>
                <CommonCreateTicketForm
                    showForm={props.showCard}
                    record={record}
                    saveInProgress={props.saveInProgress}
                    customActionEnabled={false}
                    onCreateTicket={props.onSave}
                    onClosePage={props.onCancel}
                    onSetRecord={(record:Ticket)=>setRecord(record)}
                />
                <Stack.Item key="modalFooterContent">
                    <DialogFooter>
                        <DefaultButton text={t('common:PageContainer:card:defaultButtonText')!} onClick={props.onCancel} disabled={props.saveInProgress} />
                        <PrimaryButton text={t('common:PageContainer:card:primaryButtonText')!} onClick={() => props.onSave(record)} disabled={props.saveInProgress} />
                    </DialogFooter>
                </Stack.Item>
                <RoleCenterHelpPanel
                    isOpen={showHelpPanel}
                    value={searchKeyword}
                    customStyle={{zIndex:'50000 !important'}}
                    onChange={(_,newValue)=>{setSearchKeyword(newValue!)}}
                    onClear={() => {
                        setSearchKeyword("");
                        setLastSearch("");
                        setKnowledgeBases([]);
                        sethelpPanelStatus(SystemDataLoadingStatus.ToLoad);
                    }}
                    onDismiss={() => {
                        setModalStyle(fullModalIStyle);
                        setShowHelpPanel(false);
                    }}
                    onSearch={(newValue) => handleSearchKeyword(newValue!, true)}
                    onBlur={() => {
                        if (searchKeyword)
                            handleSearchKeyword(searchKeyword)
                    }}
                    kbList={knowledgeBases}
                    dataStatus={helpPanelStatus}
                />
        </Dialog>
    );
}