import { ImageFit, PrimaryButton, Stack, Image, mergeStyles, TextField, Separator, Text, ITextProps } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import logo from '../../../Assets/Images/logoSoSmart.png';
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { Loader } from "../Loader/Loader";
import { SystemDataLoadingStatus } from "../../../Model/SystemModels";
import { t } from "i18next";

const loginBoxPageContainer = mergeStyles([{
    width: '100%',
    height: '100vh',
    backgroundColor: '#e9ecef !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}]);

const loginLogoContainer = mergeStyles([{
    width: '100%',
    borderBottom: '2px solid rgba(0,0,0,.125)'
}]);

const loginBoxContainer = mergeStyles([{
    width: '22%',
    maxHeight: '550px',
    minWidth: '425px',
    backgroundColor: 'white',
    border: '0 solid rgba(0,0,0,.125)',
    boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)',
    borderRadius: 8,
    borderTop: '5px solid #007bff'
}]);

const windowsLoginContainer = mergeStyles([{
    width: '100%',
    paddingTop: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}]);

export const ForgotPasswordBox : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [showLoader, setShowLoader] = useState(true);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [hash, setHash] = useState("");
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {        
        if(dataStatus === SystemDataLoadingStatus.ToLoad) {
            const currentParams = new URLSearchParams(location.search);
            let email = currentParams.get("email");
            if (email)
                setEmail(atob(email));

            checkHashParameter();
            setDataStatus(SystemDataLoadingStatus.Loaded);
        }
    }, [dataStatus]);
    
    const checkHashParameter = () => {
        var url = new URL(window.location.toString());
        var hash = url.searchParams.get("hash");

        if (hash != null)
        {
            window.history.replaceState(null, '', window.location.pathname);

            CommonFunctions.checkHashParameter(hash!)
                .then(() => {
                    setHash(hash!);
                    setShowLoader(false);
                })
                .catch(() => {
                    ToastService.showMessage(MessageType.Error, t('common:ForgotPasswordBox:Err00001Text')!);
                    navigate("/login");
                });
        }
        else
            window.location.replace("login");
    };

    const handleOnSave = () => {
        if (!password) {
            ToastService.showMessage(MessageType.Error, t('common:ForgotPasswordBox:passwordMandatory')!);
            return;
        }

        if (password !== confirmPassword) {
            ToastService.showMessage(MessageType.Error, t('common:ForgotPasswordBox:passwordValidation')!);
            return;
        }

        setSaveInProgress(true);

        CommonFunctions.processPasswordReset(hash!, password)
            .then((resp) => {
                ToastService.showMessage(MessageType.Success, t('common:ForgotPasswordBox:passwordConfirmation')!);
                window.location.replace(resp);
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, err);
                setSaveInProgress(false);
            });
    }

    if (showLoader)
    {
        return (<Loader text={t('common:ForgotPasswordBox:loaderMessage')!} />);
    }
    else
        return (
            <Stack className={loginBoxPageContainer}>
                <Stack className={loginBoxContainer}>
                    <Stack.Item className={loginLogoContainer}>
                        <Image src={logo} imageFit={ImageFit.cover} style={{
                            padding: 5, 
                            marginTop: 4,
                            marginBottom: 8
                        }}></Image>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack tokens={{
                            childrenGap: 10
                        }}>
                            <Stack.Item className={windowsLoginContainer}>
                                <Stack style={{
                                    width: '90%',
                                    marginBottom: 40
                                }}>
                                    <Text key="forgotPasswordText" variant={"small" as ITextProps["variant"]}>{t('common:ForgotPasswordBox:passwordMessage')!}</Text>
                                    <Separator />
                                    {(email) ? <TextField disabled value={email} label="Email" /> : ""}
                                    <TextField value={password} onChange={(e, newVal) => {
                                        setPassword(newVal!);
                                    }} 
                                    type="password" 
                                    label={t('common:ForgotPasswordBox:passwordLabel')!}
                                    canRevealPassword 
                                    required
                                    disabled={saveInProgress} />
                                    <TextField value={confirmPassword} onChange={(e, newVal) => {
                                        setConfirmPassword(newVal!);
                                    }} 
                                    type="password" 
                                    label={t('common:ForgotPasswordBox:confirmPasswordLabel')!}
                                    canRevealPassword 
                                    required 
                                    disabled={saveInProgress} />
                                    <PrimaryButton text={t('common:ForgotPasswordBox:confirmButtonLabel')!} style={{
                                        marginTop: 25,
                                        borderRadius: 20
                                    }}
                                    onClick={handleOnSave} />
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
        );
}