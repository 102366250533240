import React, { useRef, useState } from "react";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { AdminCrmLeadInteractionCard } from "../AdminCrmLeadInteractionCard/AdminCrmLeadInteractionCard";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { Loader } from "../../../Common/Loader/Loader";
import { AdminBCNCustomerProcessModuleCard } from "../AdminBCNCustomerProcessModuleCard/AdminBCNCustomerProcessModuleCard";

export const AdminBCNCustomerProcessModuleList : React.FC = () => {
    const [customerId,setCustomerId] = useState(HttpHelper.getParameter("customerId"));
    const [loaderMessage,setLoaderMessage] = useState<string>("");
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const cardRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    let listTitle = "Lista moduli processo cliente";

    const buildListEndpoint = () : string =>{
        const customerId = HttpHelper.getParameter("customerId")
        const envName = HttpHelper.getParameter("envName")
        const name = HttpHelper.getParameter("name")
        let endpoint = "/odata/bcn/customer/module";
        
        if ((customerId !== null && customerId !== "") && (envName !== null && envName !== ""))
            endpoint += "?$filter=customerId eq "+customerId+" and environmentName eq '"+envName+"'";
        
        return endpoint;
    }

    // const syncCustomerBcnProcessModule = ()=>{
    //     setLoaderMessage("Sincronizzazione moduli processo in corso...");
    //     setDataStatus(SystemDataLoadingStatus.ToLoad);
    //     adminService.SyncBCNCustomerProcessModule(customerId).then((resp)=>{
    //         setDataStatus(SystemDataLoadingStatus.Loaded);
    //         setLoaderMessage("");
    //         ToastService.showMessage(MessageType.Success,"Sincronizzazione moduli completata con successo");
    //     })
    //     .catch((jqXHR)=>{
    //         setDataStatus(SystemDataLoadingStatus.Loaded);
    //         setLoaderMessage("");
    //         ToastService.showMessage(MessageType.Error,"Error: "+jqXHR.message);
    //     })
    // }
    
    if(dataStatus === SystemDataLoadingStatus.ToLoad)
        return <Loader text={loaderMessage}/>

    return (
        <>
            <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={listTitle} cardRef={cardRef} backAllowed showAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Process}>
                        {/* <ActionEntry iconName="Sync" label="Sincronizza muduli processo" name="SyncBcnProcessModule" onClick={(rec:any)=>{
                            syncCustomerBcnProcessModule();
                        }}>
                        </ActionEntry> */}
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={50} maxWidth={50} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={90} maxWidth={90} />
                    <ListHeaderEntry name="Abilitato" fieldName="enabled" minWidth={90} maxWidth={90} />
                    <ListHeaderEntry name="Interview question completato" fieldName="interviewQuestionCompleted" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Nr. task completati" fieldName="noTaskCompleted" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Nr. task da completare" fieldName="noTaskToBeCompleted" minWidth={90} maxWidth={90} />
                    <ListHeaderEntry name="Nr. fase onBoarding" fieldName="onBoardingPhaseNo" minWidth={90} maxWidth={90} />
                    <ListHeaderEntry name="Visibile prevendita" fieldName="presaleVisible" minWidth={150} maxWidth={150} />
                    <ListHeaderEntry name="Conteggio processo" fieldName="processCount" minWidth={150} maxWidth={150} />                    
                </PageDefinitionContainer>
            </PageContainer>
            <AdminBCNCustomerProcessModuleCard ref={cardRef} />
        </>
    );
}