import { rejects } from "assert";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import i18next from "i18next";
import jwt from 'jwt-decode';
import { resolve } from "path";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { IErrorDialogProps } from "../../Components/Common/ErrorDialog/ErrorDialog";
import { UserAuthenticationResult, UserCredential } from "../../Model/SecurityModel";
import { MenuEntry, SystemApp, SystemDataLoadingStatus, User, UserSessionInformation, UserSettings } from "../../Model/SystemModels";
import { CookieService } from "../Cookie/CookieHelper";
import { HttpConstants } from "../Http/HttpConstants";
import { HttpHelper } from "../Http/HttpHelper";
import { ISessionStateContext } from "../State/SessionStateContext";
import { BCNProcessTest } from "../../Model/DemoModels";

export class SystemCore {

    constructor()
    {}

    public static getSystemAppPublicInfo(baseAddress: string): Promise<string>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/api/System/app/"+btoa(baseAddress)+"/informations";
        return new Promise((resolve,reject)=>{
            instance.get(url)
            .then((resp)=>{
                resolve(resp.data);
            })
            .catch((resp)=>{
                reject();
            })
        })
    }

    public static getUserChars(currentUser: User): string
    {
        if (((currentUser.firstName != "") && (currentUser.firstName !== null)) && ((currentUser.lastName != "") && (currentUser.lastName !== null)))
            return currentUser.firstName[0].toUpperCase() + currentUser.lastName[0].toUpperCase();
        else
        {
            let chars = '';
            let names = currentUser.displayName.split(' ');
            for (let i = 0; i < names.length; i++)
                chars += names[i][0].toUpperCase();
            
            return chars;
        } 
    }

    public static createErrorDialogProps(title: string, message: string, onUpdateClick: () => void) : IErrorDialogProps {
        let errorProps: IErrorDialogProps = {} as IErrorDialogProps;
        errorProps.showDialog = true;
        errorProps.title = title;
        errorProps.text = message;
        errorProps.onUpdateClick = onUpdateClick;
        return errorProps;
    }

    public authenticateUser(credential: UserCredential) : Promise<UserAuthenticationResult>
    {
        let authenticationResult: UserAuthenticationResult = new UserAuthenticationResult();
        let client = HttpHelper.buildAxiosInstance();
        return new Promise<UserAuthenticationResult>((resolve, reject) => {
            client.post("/api/system/login", credential).then((resp) => {
                authenticationResult.success = resp.data.success;
                if (authenticationResult.success)
                    authenticationResult.success = this.setAccessTokenCookie(resp.data.value);
                
                resolve(authenticationResult);
            })
            .catch((error) => {
                authenticationResult.success = false;
                authenticationResult.error = error.response.data.message;
                return reject(authenticationResult);
            });
        });
    } 

    public checkUserPassword(credential: UserCredential) : Promise<boolean>
    {
        let client = HttpHelper.buildAxiosInstance();
        return new Promise<boolean>((resolve, reject) => {
            client.post("/api/system/password/check", credential).then((resp) => {
                console.log(resp);
                console.log(resp.data);
                const result: boolean = resp.data;                
                resolve(result);
            })
            .catch((error) => {
                resolve(false);
            });
        });
    } 

    public authenticateUserFromParameter(token: string) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        instance.defaults.headers.common[HttpConstants.HEADER_AUTHORIZATION] = HttpConstants.BEARER_AUTH + " " + token;
        return new Promise<boolean>((resolve, reject) => {
            instance.get("api/system/app/change")
                .then((resp) => {
                    let result: UserAuthenticationResult = resp.data;
                    if (result.success)
                    {
                        this.setAccessTokenCookie(resp.data.value);
                        resolve(true);
                    }

                    reject();
                })
                .catch((err) => {
                    reject(err.response.data.message);
                })
        });
    }

    public authenticateUserFromRefreshToken() : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();

        return new Promise<boolean>((resolve, reject) => {
            instance.get("api/System/app/change")
                .then((resp) => {
                    let result: UserAuthenticationResult = resp.data;
                    if (result.success)
                    {
                        this.setAccessTokenCookie(resp.data.value);
                        resolve(true);
                    }

                    reject();
                })
                .catch((err) => {
                    reject(err.response.data.message);
                })
        });
    }

    public authenticateMicrosoftUser(data: string) : Promise<UserAuthenticationResult>
    {
        let result: UserAuthenticationResult = new UserAuthenticationResult();
        let client = HttpHelper.buildAxiosInstance();

        return new Promise<UserAuthenticationResult>((resolve, reject) => {
            let equalSplitItems = data.split("=");
            if (equalSplitItems.length == 0)
            {
                result.success = false;
                result.error = "Autenticazione non riuscita";
                resolve(result);     
            }

            let andSplitItems = equalSplitItems[1].split("&");
            if (andSplitItems.length == 0)
            {
                result.success = false;
                result.error = "Autenticazione non riuscita";
                resolve(result);     
            }

            client.post("api/system/login/microsoft", andSplitItems[0])
                .then((resp) => {
                    result.success = resp.data.success;
                    if (result.success)
                        result.success = this.setAccessTokenCookie(resp.data.value);

                    resolve(result);
                })
                .catch((err) => {
                    result.success = false;
                    result.error = err.response.data.message;
                    resolve(result);
                })
        });
    }

    public getUserSessionInformation() : Promise<UserSessionInformation>
    {
        let instance = HttpHelper.buildAxiosInstance();
        return new Promise((resolve, reject) => {
            instance.get("api/system/session/information").then((resp) => {
                let sessionInfo: UserSessionInformation = resp.data;
                resolve(sessionInfo);
            })
            .catch((err) => {
                reject(err.response.data);
            });
        })
    }

    public manageUserLicense(userId: number) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        return new Promise((resolve, reject) => {
            instance.post("api/system/manage/user/"+userId+"/license")
            .then(() => {
                resolve(true);
            })
            .catch((err) => {
                reject(err.response.data);
            })
        });
    }

    public getUserFeatures() : Promise<MenuEntry[]>
    {
        let instance = HttpHelper.buildAxiosInstance();
        return new Promise((resolve, reject) => {
            instance.get("api/system/features").then((resp) => {
                let resultArray: MenuEntry[] = resp.data;
                resolve(resultArray);
            })
            .catch((err) => {
                reject(err.response.data);
            });
        });
    }

    public UserSessionExpired(context: ISessionStateContext, navigate: NavigateFunction)
    {
        context.currentApp = new SystemApp();
        context.currentUser = new User();
        context.isAuthenticated = false;
        CookieService.removeCookie(HttpConstants.ACCESSTOKEN_COOKIE_NAME);

        navigate("/login?resource=" + btoa(window.location.pathname + window.location.search));
    }

    public getUserToken() : string
    {
        let token = CookieService.getCookieValue(HttpConstants.ACCESSTOKEN_COOKIE_NAME, true);
        if (token)
            return token;
        return "";
    }

    public static isFinishedDataStatus(status: SystemDataLoadingStatus)
    {
        return ((status == SystemDataLoadingStatus.Error) || (status == SystemDataLoadingStatus.Loaded));
    }

    public logOutUser(context: ISessionStateContext, navigate: NavigateFunction)
    {
        context.currentApp = new SystemApp();
        context.currentUser = new User();
        context.isAuthenticated = false;
        CookieService.removeCookie(HttpConstants.ACCESSTOKEN_COOKIE_NAME);

        navigate("/login");
    }

    private setAccessTokenCookie(token: string) : boolean
    {
        let tokenClaims: any = jwt(token);
        if (tokenClaims.appid == undefined)
            return false;

        let tokenExpiration = new Date(0);
        tokenExpiration.setUTCSeconds(tokenClaims.exp);

        CookieService.setCookieValue(HttpConstants.ACCESSTOKEN_COOKIE_NAME, token, tokenExpiration, true);
        return true;
    }
    
    public setUserLanguageCode(userSettings:UserSettings) : Promise<boolean>
    {
        let instance = HttpHelper.buildAxiosInstance();
        let url = "/odata/user/settings/"+userSettings.systemId;
        let updatedSettings = userSettings;
        updatedSettings.userLanguageCode = this.formatLanguage(i18next.language);
        return new Promise((resolve, reject) => {
            instance.patch(url, updatedSettings).then((resp) => {
                resolve(true);
            })
            .catch((err) => {
                reject(err.response.data);
            });
        });
    }

    public formatLanguage(value: string) : string
    {
        let currLanguageCode = value;
        if(currLanguageCode.includes('-'))
            currLanguageCode = currLanguageCode.split('-')[0];
        
        return currLanguageCode.toLocaleUpperCase();
    }

    public static setFavicons = (favImg:string) => {
        let headTitle = document.querySelector('head');
        let setFavicon = document.createElement('link');

        setFavicon.setAttribute('rel','shortcut icon');
        setFavicon.setAttribute('href',favImg);
        headTitle!.appendChild(setFavicon);
    }
    
    public static searchKB(client: AxiosInstance, input: string, userSearch: boolean) : Promise<BCNProcessTest[]>
    {
        let url = "api/CSP/azure/openai/search/string/"+10+"/"+userSearch;
        return new Promise((resolve, reject) => {
            client.post(url, input, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((resp) => {
                reject(resp.message.data);
            })
        })
    }
}